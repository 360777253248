import Select from 'react-select'
import StatusText from '../StatusText/StatusText'
import PropTypes from 'prop-types'

const StatusDropDown = ({
    kind,
    status,
    reference,
    value,
    onChange,
    labelOnSide = true,
}) => {
    const options = [
        {
            value: 'COMPLETED',
            label: <StatusText status="COMPLETED" kind={kind} />,
        },
        {
            value: 'RECEIVED',
            label: <StatusText status="RECEIVED" kind={kind} />,
        },
        {
            value: 'IN_REVIEW',
            label: <StatusText status="IN_REVIEW" kind={kind} />,
        },
        {
            value: 'DECLINED',
            label: <StatusText status="DECLINED" kind={kind} />,
        },
    ]

    const formatOptionLabel = ({ label, customAbbreviation }) => (
        <div className="flex justify-start text-left">
            <div style={{ color: customAbbreviation, display: '' }}>
                {label}
            </div>
        </div>
    )

    const labelClasses = labelOnSide
        ? 'text-right items-start md:items-center' // LABEL WILL BE ON THE LEFT
        : 'flex-col' // LABEL WILL BE ON TOP

    return (
        <>
            <label
                className={
                    labelClasses +
                    ' justify-end gap-3 font-bold text-primary flex flex-col md:flex-row'
                }
            >
                <p className="text-md">Κατάσταση:</p>

                <Select
                    className="w-full lg:w-2/3"
                    isSearchable={false}
                    defaultValue={{
                        label: <StatusText status={status} kind={kind} />,
                        value: status,
                    }}
                    value={value}
                    formatOptionLabel={formatOptionLabel}
                    options={options}
                    name="status"
                    ref={reference}
                    onChange={onChange}
                    placeholder="Επιλογή κατάστασης"
                    theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#F2F2F2',
                            primary: '#F2F2F2',
                        },
                    })}
                />
            </label>
        </>
    )
}

StatusDropDown.propTypes = {
    kind: PropTypes.oneOf(['payment', 'balance-request', 'kok']),
    status: PropTypes.string,
    value: PropTypes.any,
    reference: PropTypes.object,
    onChange: PropTypes.func,
    labelOnSide: PropTypes.bool,
}

export default StatusDropDown
