import Modal from '../components/Modal/Modal'
import Loading from '../components/Loading/Loading'
import Header from '../components/Header/Header'
import StatusText from '../components/StatusText/StatusText'
import PopupMessage from '../components/PopupMessage/PopupMessage'

import { useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import useMediaQuery from '../hooks/useMediaQuery'

import { formatDate } from '../helpers/formatters'
import { useQuery } from '@tanstack/react-query'
import { getMunicipalAwarenessRequest } from '../api/municipal-awareness-requests'

import { Buffer } from 'buffer'
import downloadIcon from '../assets/images/download.svg'

const MunicipalAwarenessRequestMore = () => {
    const { token } = useContext(AuthContext)
    const isMobile = useMediaQuery('(max-width:1024px)')
    const { id: municipalAwarenessRequestId } = useParams()
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })

    const { isLoading, data: municipalAwarenessRequest } = useQuery({
        queryKey: ['municipalAwarenessRequest', options],
        queryFn: () =>
            getMunicipalAwarenessRequest(municipalAwarenessRequestId, options),
    })

    useEffect(() => {
        document.title =
            'Λεπτομέρειες - Πλατφόρμα Ηλ. Πληρωμών Δήμου Δυτικής Λέσβου'
    }, [])

    if (isLoading) {
        return <Loading />
    }

    let base64File = null

    if (municipalAwarenessRequest.data.canBeIssued) {
        try {
            base64File = Buffer.from(
                municipalAwarenessRequest.data.municipalAwarenessFile
            ).toString('base64')
        } catch (err) {
            console.error(err)
        }
    }

    let textToShow, textColor

    switch (municipalAwarenessRequest.data.canBeIssued) {
        case null:
            textToShow = 'Αναμονή απάντησης από το Δήμο'
            textColor = 'text-yellow-400'
            break
        case false:
            textToShow = 'Δε μπορεί να εκδοθεί λόγω οφειλών προς το Δήμο'
            textColor = 'text-red-400'
            break
        case true:
            textToShow = 'Αρχείο δημοτικής ενημερότητας προς λήψη'
            textColor = 'text-green'
            break
        default:
            textToShow = 'Αναμονή απάντησης από το Δήμο'
    }

    const mainContent = (
        <>
            <div className="flex flex-col self-center justify-around">
                <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                    <div className="w-full text-left lg:text-right lg:w-1/3">
                        Κωδικός Αίτησης:
                    </div>
                    <div className="w-full text-secondary lg:w-[50%] lg:border-b">
                        {municipalAwarenessRequest.data.title}
                    </div>
                </div>

                <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                    <div className="w-full text-left lg:text-right lg:w-1/3">
                        Ημερομηνία Υποβολής Αίτησης:
                    </div>
                    <div className="w-full text-secondary lg:w-[50%] lg:border-b">
                        {formatDate(municipalAwarenessRequest.data.createdAt)}
                    </div>
                </div>
                <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                    <div className="w-full text-left lg:text-right lg:w-1/3">
                        Κατάσταση:
                    </div>

                    <StatusText
                        kind="request"
                        status={municipalAwarenessRequest.data.status}
                    />
                </div>
                <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                    <div className="w-full text-left lg:text-right lg:w-1/3">
                        Δημοτική Ενημερότητα:
                    </div>
                    <div
                        className={`w-full lg:w-[50%] lg:border-b ${textColor}`}
                    >
                        <p>{textToShow}</p>
                    </div>
                </div>

                {municipalAwarenessRequest.data.canBeIssued ? (
                    <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14">
                        <div className="w-full text-left lg:text-right lg:w-1/3">
                            Λήψη Αρχείου:
                        </div>

                        <div className="w-full text-darkBlue lg:w-[50%] lg:border-b">
                            <a
                                href={`data:application/pdf;base64,${base64File}`}
                                download={`Δημοτική Ενημερότητα - ${municipalAwarenessRequest.data.createdAt}.pdf`}
                                className="flex items-center gap-2 w-fit mb-1"
                            >
                                Δημοτική Ενημερότητα.pdf
                                <img src={downloadIcon} alt="Λήψη" />
                            </a>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    )

    return (
        <>
            {municipalAwarenessRequest.data &&
                (isMobile ? (
                    <>
                        <div className="flex flex-col">
                            {popup.shown && (
                                <PopupMessage
                                    message={popup.message}
                                    type={popup.type}
                                    closePopUp={() =>
                                        setPopup(values => ({
                                            ...values,
                                            shown: false,
                                        }))
                                    }
                                    showCloseButton={true}
                                />
                            )}

                            <div className="flex flex-col pb-32">
                                <header className="text-3xl text-center py-8">
                                    Λεπτομέρειες Αίτησης Δημοτικής Ενημερότητας
                                </header>

                                <Modal classes="w-[80%] pl-8 pr-12 pb-8 mb-10 pt-12 self-center md:w-1/2 md:mb-0">
                                    {mainContent}
                                </Modal>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <Modal classes="w-full max-w-7xl flex flex-col">
                            <Header
                                includeLogo={false}
                                withBorder={true}
                                includeButtons={true}
                            />

                            <div className="flex flex-col">
                                {popup.shown && (
                                    <PopupMessage
                                        message={popup.message}
                                        type={popup.type}
                                        closePopUp={() =>
                                            setPopup(values => ({
                                                ...values,
                                                shown: false,
                                            }))
                                        }
                                        showCloseButton={true}
                                    />
                                )}
                                <div className="flex flex-col pb-5 my-10">
                                    <Modal classes="w-[75%] 2xl:w-1/2 pb-8 self-center md:pt-14 lg:pt-2">
                                        <header className="font-light text-3xl text-center border-b border-primary py-6 self-stretch mb-10">
                                            Λεπτομέρειες Αίτησης Δημοτικής
                                            Ενημερότητας
                                        </header>

                                        {mainContent}
                                    </Modal>
                                </div>
                            </div>
                        </Modal>
                    </>
                ))}
        </>
    )
}

export default MunicipalAwarenessRequestMore
