import Modal from '../../components/Modal/Modal'
import StatusDropDown from '../../components/StatusDropDown/StatusDropDown'
import PopupMessage from '../../components/PopupMessage/PopupMessage'
import Loading from '../../components/Loading/Loading'

import { useParams, useNavigate, Link } from 'react-router-dom'
import { useContext, useRef, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { formatAmount } from '../../helpers/formatters'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { getPayment, updatePayment } from '../../api/payments'

import notFound from '../../assets/images/admin404.png'

const AdminPaymentEdit = () => {
    useEffect(() => {
        document.title =
            'Επεξεργασία Πληρωμής Οφειλής - Πλατφόρμα Ηλ. Πληρωμών Δήμου Αντιπάρου'
    }, [])

    const statusRef = useRef(null)
    const navigate = useNavigate()
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })
    const { id: paymentId } = useParams()
    const { token } = useContext(AuthContext)
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }
    const queryClient = useQueryClient()

    const { isLoading, data } = useQuery({
        queryKey: ['adminPayment', paymentId, options],
        queryFn: () => getPayment(paymentId, options),
    })

    const mutation = useMutation({
        mutationFn: updatedPayment =>
            updatePayment(paymentId, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedPayment),
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['adminPayment'],
            })

            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <p>
                        Οι αλλαγές υποβλήθηκαν με επιτυχία! Μπορείτε να δείτε τη
                        νέα κατάσταση της πληρωμής στον πίνακα με τις&nbsp;
                        <Link className="font-bold" to={'/admin/payment'}>
                            &quot;Πληρωμές Οφειλών&quot;
                        </Link>
                    </p>
                ),
                type: 'success',
            }))

            setTimeout(() => {
                navigate('/admin/payment')
            }, 3000)
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Η πληρωμή δεν ενημερώθηκε.</p>
                        <p>Παρακαλώ προσπαθήστε ξανά</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    if (isLoading) {
        return <Loading />
    }

    const paymentData = data?.data

    if (!paymentData) {
        return (
            <>
                <div>
                    <img
                        src={notFound}
                        className="pb-10"
                        alt="Page not found"
                    />
                </div>
            </>
        )
    }

    return (
        paymentData && (
            <>
                {popup.shown && (
                    <PopupMessage
                        message={popup.message}
                        type={popup.type}
                        closePopUp={() =>
                            setPopup(values => ({ ...values, shown: false }))
                        }
                        showCloseButton={true}
                    />
                )}

                <div className="flex flex-col">
                    <div className="flex flex-col pb-32">
                        <header className="text-3xl text-center py-8">
                            Επεξεργασία Κατάστασης Πληρωμής
                        </header>

                        <Modal classes="w-[80%] pl-8 pr-12 pb-8 pt-20 self-center md:w-1/2">
                            <div className="flex flex-col self-center justify-around">
                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        ΑΦΜ Οφειλέτη:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {paymentData?.user?.profile.taxid ??
                                            'Διεγραμμένος Χρήστης'}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ονοματεπώνυμο Οφειλέτη:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {paymentData?.user?.profile?.lastName
                                            ? `${paymentData.user.profile.firstName} ${paymentData.user.profile.lastName}`
                                            : 'Διεγραμμένος Χρήστης'}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Κωδικός Συστήματος:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {paymentData.systemCode ?? '-'}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Κωδικός Οφειλής:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {paymentData.paymentCode ?? '-'}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Αιτιολογία:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {paymentData.description ?? '-'}
                                    </div>
                                </div>
                                <div className="text-base text-primary mb-2 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-8">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ποσό:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {formatAmount(paymentData.amount) ?? 0}
                                    </div>
                                </div>
                                <StatusDropDown
                                    status={paymentData.status}
                                    kind="payment"
                                    reference={statusRef}
                                />
                                <button
                                    className="mt-14 bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all rounded-lg w-fit py-3.5 px-10 self-center md:self-end"
                                    type="submit"
                                    onClick={() =>
                                        mutation.mutate({
                                            status: statusRef.current.props
                                                .value.value,
                                        })
                                    }
                                    disabled={mutation.isLoading}
                                >
                                    Αποθήκευση
                                </button>
                            </div>
                        </Modal>
                    </div>
                </div>
            </>
        )
    )
}

export default AdminPaymentEdit
