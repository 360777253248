import './NavItem.css'

import { NavLink, useLocation } from 'react-router-dom'
import { useState } from 'react'
import PropTypes from 'prop-types'

const NavItem = ({
    path,
    text,
    classes,
    hasSubmenu = false,
    subMenuItems,
    closeFunction,
}) => {
    const [expanded, setExpanded] = useState(false)
    const location = useLocation()
    const currentPath = location.pathname
    const isAdminPath = location.pathname.includes('/admin')

    return (
        <>
            {hasSubmenu ? (
                <li>
                    <button
                        onClick={() => setExpanded(!expanded)}
                        className={`first-of-type:mt-4 ${classes} ${
                            expanded ? 'bg-accent text-white' : 'mb-4'
                        }`}
                    >
                        {text}
                    </button>

                    <div
                        className={`flex flex-col gap-4 mt-4 mb-6 ml-4 ${
                            expanded ? 'block' : 'hidden'
                        }`}
                    >
                        {subMenuItems.map((item, index) => {
                            return (
                                <NavLink
                                    key={index}
                                    to={item.path}
                                    className={`w-fit pb-2 ${
                                        currentPath === item.path
                                            ? 'border-b-4 border-accent'
                                            : ''
                                    }`}
                                    onClick={closeFunction}
                                >
                                    {item.text}
                                </NavLink>
                            )
                        })}
                    </div>
                </li>
            ) : (
                <li className={classes}>
                    <NavLink
                        to={path}
                        onClick={closeFunction}
                        className={`pb-2 ${classes} ${
                            currentPath === path && !isAdminPath
                                ? 'border-b-4 border-accent'
                                : ''
                        }`}
                    >
                        {text}
                    </NavLink>
                </li>
            )}
        </>
    )
}

NavItem.propTypes = {
    path: PropTypes.oneOf([PropTypes.string, null]),
    text: PropTypes.string,
    classes: PropTypes.string,
    hasSubmenu: PropTypes.bool,
    subMenuItems: PropTypes.array,
    closeFunction: PropTypes.func,
}

export default NavItem
