import govpayLogo from '../../assets/images/govpay-logo.svg'

const Loading = () => {
    return (
        <>
            <div className="flex flex-col items-center w-full justify-center z-10">
                <img
                    className="animate-bounce"
                    width="200"
                    height="200"
                    src={govpayLogo}
                    alt="govpay-logo"
                ></img>
            </div>
        </>
    )
}

export default Loading
