import _fetch from '.'

export async function getMunicipalAwarenessRequestsOfUser(
    userId,
    options = {}
) {
    const url = `${process.env.REACT_APP_API_URL}/users/${userId}/municipal-awareness-requests`
    return _fetch(url, options)
}

export async function createMunicipalAwarenessRequest(options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/municipal-awareness-requests`
    return _fetch(url, options)
}

export async function getMunicipalAwarenessRequest(
    municipalAwarenessRequestId,
    options = {}
) {
    const url = `${process.env.REACT_APP_API_URL}/municipal-awareness-requests/${municipalAwarenessRequestId}`
    return _fetch(url, options)
}

export async function getAllMunicipalAwarenessRequests(filters, options = {}) {
    const { afm: taxid, status, title } = filters

    const url = new URL(
        `${process.env.REACT_APP_API_URL}/municipal-awareness-requests`
    )

    url.searchParams.set('taxid', taxid)
    url.searchParams.set('status', status)
    url.searchParams.set('title', title)

    return _fetch(url, options)
}

export async function updateMunicipalAwarenessRequest(
    municipalAwarenessRequestId,
    options = {}
) {
    const url = `${process.env.REACT_APP_API_URL}/municipal-awareness-requests/${municipalAwarenessRequestId}`
    return _fetch(url, options)
}
