const AdminHome = () => {
    return (
        <div className="w-full flex items-center flex-col py-20">
            <div className="max-w-2xl py-10 px-10">
                <h2 className="text-center font-bold text-3xl mb-10">
                    Καλωσήρθατε στην Θυρίδα ηλεκτρονικών πληρωμών του Δήμου
                    Δυτικής Λέσβου
                </h2>
                <p className="text-center text-secondary text-2xl">
                    Επιλέξτε στοιχεία από την αριστερή στήλη για να δείτε τις
                    οφειλές, τις πληρωμές σας, ή για να κάνετε αίτημα εύκολα και
                    με ελάχιστα κλικς.
                </p>
            </div>
        </div>
    )
}

export default AdminHome
