import Modal from '../Modal/Modal'
import Header from '../Header/Header'
import AccordionItem from '../AccordionToggle/AccordionToggle'

import useMediaQuery from '../../hooks/useMediaQuery'

import { useState } from 'react'

const faqs = [
    {
        id: 1,
        accordionTitle: 'Εγχειρίδιο Χρήσης',
        accordionContent: (
            <>
                <p>
                    Μέσω της πλατφόρμα GovPay του Δήμου Δυτικής Λέσβου έχετε τη
                    δυνατότητα να πληρώσετε μια οφειλή σας, να δείτε το ιστορικό
                    όλων των πληρωμών που έχετε πραγματοποιήσει και να αιτηθείτε
                    να μάθετε αν οφείλετε κάτι άλλο προς το Δήμο.
                </p>
                <h3>Οι Οφειλές μου</h3>
                <p>
                    Από το μενού “Οι Οφειλές μου” μπορείτε να επιλέξετε τη
                    “Πληρωμή οφειλής”, τις “Βεβαιωμένες Οφειλές, τις “Οφειλές σε
                    Ρύθμιση”, τις “Οφειλές απο Αίτηση Υπολοίπου και το “Ιστορικό
                    Πληρωμών”.
                </p>
                <h4>Πληρωμή οφειλής</h4>
                <p>
                    Συμπληρώνοντας τη φόρμα στην “Πληρωμή Οφειλής” μπορείτε να
                    εξοφλήσετε μια οφειλή σας.
                </p>
                <p>
                    Για την πληρωμή οφειλής αυτό που χρειάζεται είναι να
                    μεταβείτε στη καρτέλα “Πληρωμή Οφειλής” και να συμπληρώσετε
                    όλα τα πεδία (Κωδικός Οφειλής, Αιτιολογία και το ακριβές
                    ποσό). Στη συνέχεια πατάτε το κουμπί “Πληρωμή” και
                    μεταφέρεστε στο περιβάλλον της τράπεζας για να διεκπεραιωθεί
                    η πληρωμή της οφειλής. Μόλις ολοκληρωθεί η διαδικασία θα
                    αρχίσετε να λαμβάνετε e-mails στο ηλεκτρονικό σας
                    ταχυδρομείο σας που θα σας ενημερώνουν για την εξέλιξη της
                    πληρωμής σας μέχρι να επιβεβαιωθεί και ολοκληρωθεί από το
                    Δήμο.
                </p>
                <h4>Βεβαιωμένες Οφειλές</h4>
                <p>
                    Στον πίνακα που εμφανίζεται βλέπετε όλες τις βεβαιωμένες
                    οφειλές σας και μπορείτε να επιλέξετε μία ή και περισσότερες
                    για να πληρώσετε. Η επιλογή πρέπει να γίνεται από την
                    παλαιότερη στη πιο πρόσφατη οφειλή.
                </p>
                <p>
                    Αφού επιλέξετε τις οφειλές σας μπορείτε να πατήσετε το
                    κουμπί “Πληρωμή”, να οδηγηθείτε στο περιβάλλον της τράπεζας
                    και να πραγματοποιήσετε την πληρωμή.
                </p>
                <p>
                    Μόλις ολοκληρωθεί η πληρωμή, τότε θα εμφανιστεί στο
                    “Ιστορικό Πληρωμών”, όπου εκεί μπορεί να γίνει λήψη της
                    απόδειξης πληρωμής.
                </p>
                <h4>Οφειλές σε Ρύθμιση</h4>
                <p>
                    Στον πίνακα αυτόν εμφανίζονται όλες οι οφειλές που είναι από
                    αίτηση υπολοίπου. Μπορείτε να πληρώσετε μια ή και
                    περισσότερες οφειλές.
                </p>
                <p>
                    Με σκοπό να διεκπεραιωθεί η πληρωμή πρέπει να πατήσετε το
                    κουμπί <b>“Πληρωμή”</b> και να συνεχίσετε στο περιβάλλον της
                    τράπεζας.
                </p>
                <p>
                    Μετά την ολοκλήρωση της πληρωμής μπορείτε να τη δείτε στο
                    <b>“Ιστορικό Πληρωμών”,</b> όπου εκεί μπορεί να γίνει λήψη
                    της απόδειξης πληρωμής.
                </p>
                <p>
                    Επιπρόσθετα, υπάρχει η δυνατότητα για έκδοση ειδοποιητηρίου
                    απλά επιλέγοντας τις οφειλές που επιθυμείτε και πατώντας το
                    κουμπί “Έκδοση ειδοποιητηρίου”.
                </p>
                <h4>Ιστορικό Πληρωμών</h4>
                <p>
                    Στη καρτέλα “Ιστορικό Πληρωμών” μπορείτε να δείτε όλες τις
                    πληρωμές οφειλών που έχετε πραγματοποιήσει και να
                    “κατεβάσετε” το αντίγραφο πληρωμής σας πατώντας το κουμπί
                    που βρίσκεται στη στήλη “Λήψη”. Πατώντας το κουμπί που
                    βρίσκεται στα “Περισσότερα” βλέπετε περισσότερες
                    λεπτομέρειες σχετικά την πληρωμή σας.
                </p>
                <h4>Κλήσεις Κ.Ο.Κ</h4>
                <p>
                    Από το μενού “Κλήσεις Κ.Ο.Κ” μπορείτε να επιλέξετε το “Οι
                    κλήσεις μου” και το Ιστορικό Κλήσεων”.
                </p>
                <h4>Οι κλήσεις μου</h4>
                <p>
                    Στο πίνακα “Κλήσεις Κ.Ο.Κ” εμφανίζονται όλες οι κλήσεις που
                    τυχόν έχετε και επιλέγοντας αυτές που θέλετε μπορείτε να
                    προχωρήσετε στην πληρωμή τους.
                </p>
                <h4>Ιστορικό Κλήσεων</h4>
                <p>
                    Στο πίνακα “Κλήσεις Κ.Ο.Κ” εμφανίζονται όλες οι κλήσεις που
                    τυχόν έχετε και επιλέγοντας αυτές που θέλετε μπορείτε να
                    προχωρήσετε στην πληρωμή τους.
                </p>
                <h4>Αίτημα - Βεβαίωση</h4>
                <p>
                    Στη καρτέλα “Αίτημα - Βεβαίωση” μπορείτε να ζητήσετε από το
                    Δήμο να σας ενημερώσει αν υπάρχει κάποια οφειλή προς
                    εξόφληση πατώντας το κουμπί “Αίτηση Υπολοίπου” ή να
                    αιτηθείτε Δημοτική Ενημερότητα .
                </p>
                <p>
                    Καθ’ όλη τη πορεία του αιτήματος σας θα ενημερώνεστε μέσω
                    e-mail για την εξέλιξή του.
                </p>
                <p>
                    Μόλις η κατάσταση του αιτήματός υπολοίπου σας ολοκληρωθεί,
                    τότε αν πατήσετε στο “Περισσότερα” που βρίσκεται στο πίνακα
                    του “Ιστορικό Αιτήσεων”, θα οδηγηθείτε σε μία νέα σελίδα που
                    θα έχει αναλυτικά τις οφειλές που τυχόν υπάρχουν προς το
                    Δήμο.
                </p>
                <p>
                    Αν υπάρχει κάποια οφειλή, τότε πατώντας το κουμπί “Πληρωμή”
                    οδηγείστε στο περιβάλλον της τράπεζας ώστε να ολοκληρώσετε
                    την πληρωμή σας.
                </p>
                <p>
                    Όταν ολοκληρωθεί το αίτημα σας για την έκδοση Δημοτικής
                    ενημερότητας, τότε πατώντας το κουμπί “Περισσότερα”
                    οδηγείστε σε μια νέα σελίδα όπου μπορείτε να “κατεβάσετε”
                    την ενημερότητα αν την δικαιούστε.
                </p>
                <h4>Ανανέωση Χρόνου</h4>
                <p>
                    H διάρκεια σύνδεσης στην πλατφόρμα έχει διάρκεια 15 λεπτά
                    για λόγους ασφαλείας. Αν επιθυμείτε τη μεγαλύτερη παραμονή
                    σας θα πρέπει να κάνετε ανανέωση στο κουμπί που βρίσκεται
                    δίπλα στο προφίλ σας.
                </p>
                <p>
                    Τα τελευταία 3 λεπτά πριν λήξει η συνεδρία θα σας εμφανιστεί
                    μήνυμα, ώστε να προβείτε σε ανανέωση του χρόνου, διαφορετικά
                    μετά το πέρας των 15 λεπτών θα αποσυνδεθείτε.
                </p>
            </>
        ),
    },
    {
        id: 2,
        accordionTitle: 'Προϋποθέσεις Πληρωμής',
        accordionContent: (
            <ul className="list-disc">
                <li>
                    Είστε αποκλειστικά υπεύθυνοι για την ορθότητα των στοιχείων
                    που καταχωρείτε.
                </li>
                <li>
                    Η εξόφληση πρέπει να γίνεται για το ακριβές ποσό της
                    οφειλής.
                </li>
            </ul>
        ),
    },
]

const HomeLoggedIn = () => {
    const isMobile = useMediaQuery('(max-width:1024px)')

    const [active, setActive] = useState(null)

    const handleToggle = index => {
        if (active === index) {
            setActive(null)
        } else {
            setActive(index)
        }
    }
    return (
        <>
            <Modal classes="w-full max-w-7xl md:h-auto h-fit flex flex-col">
                {isMobile ? null : (
                    <Header
                        includeLogo={true}
                        withBorder={true}
                        includeButtons={true}
                    />
                )}

                <div className="w-full flex items-center flex-col py-8 md:py-20 px-7">
                    <div className="max-w-2xl py-10">
                        <h2 className="text-center font-bold text-2xl md:text-3xl mb-10">
                            Καλωσήρθατε στην Θυρίδα ηλεκτρονικών πληρωμών του
                            Δήμου Δυτικής Λέσβου
                        </h2>
                        <p className="text-center text-secondary text-xl md:text-2xl">
                            Επιλέξτε στοιχεία από την αριστερή στήλη για να
                            δείτε τις οφειλές, τις πληρωμές σας, ή για να κάνετε
                            αίτημα εύκολα και με ελάχιστα κλικς.
                        </p>
                        {faqs.map((faq, index) => {
                            return (
                                <AccordionItem
                                    key={index}
                                    active={active}
                                    handleToggle={handleToggle}
                                    faq={faq}
                                />
                            )
                        })}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default HomeLoggedIn
