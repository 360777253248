import PropTypes from 'prop-types'
import './HistoryItem.css'

const HistoryItem = ({ headings, rows }) => {
    return (
        <div className="mx-6 historybox my-10">
            {headings.map((heading, index) => {
                return (
                    <div className="w-full flex" key={index}>
                        <div
                            className={
                                'head flex items-center justify-center bg-darkBlue text-white w-1/2 p-2 text-center'
                            }
                        >
                            {heading}
                        </div>
                        <div
                            className={
                                'value flex items-center  bg-white w-1/2 p-2 overflow-hidden'
                            }
                        >
                            {rows[index]}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

HistoryItem.propTypes = {
    headings: PropTypes.arrayOf(PropTypes.string).isRequired,
    rows: PropTypes.object.isRequired,
}

export default HistoryItem
