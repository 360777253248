export async function fetchAPI(url, options = {}) {
    try {
        const res = await fetch(url, options)
        const data = await res.json()
        return data
    } catch (error) {
        console.error(error)
        return false
    }
}
