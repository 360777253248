import { useState, useEffect } from 'react'
import useDebounce from './useDebounce'

const useFetch = (url, options = {}) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [error, setError] = useState()
    const debouncer = useDebounce(url, 500)

    useEffect(() => {
        const controller = new AbortController()
        setLoading(true)

        const fetchData = async () => {
            try {
                const res = await fetch(url, {
                    ...options,
                    signal: controller.signal,
                })

                const json = await res.json()
                setData(json)
            } catch (err) {
                console.error(err)
                setError(err)
            } finally {
                setLoading(false)
            }
        }
        fetchData()

        return () => {
            controller.abort()
        }
    }, [debouncer])

    return { loading, data, error }
}

export default useFetch
