import '../Sidebar/Sidebar.css'

import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import NavItem from '../NavItem/NavItem'
import useAuth from '../../hooks/useAuth'
import logo from '../../assets/images/logo.svg'
import adminLogo from '../../assets/images/admin-logo.svg'

const userClasses = `
    nav-item
    fw-medium 
    text-lg 
    text-primary 
    hover:bg-accent 
    hover:text-white 
    py-3 
    px-2 
    rounded-lg 
    transition-all 
`

const adminClasses = `
    nav-item
    fw-medium 
    text-lg 
    text-white 
    mb-2 
    py-5 
    px-4
    transition-all
`

const disabledClasses = `
    bg-grey
    fw-medium 
    text-lg 
    text-secondary 
    mb-2 
    py-3
    px-6
    rounded-lg 
    transition-all
`

const Sidebar = ({ forAdmin = false }) => {
    const { getEmailVerified } = useAuth()
    const emailVerifed = getEmailVerified()

    return forAdmin ? (
        <aside className="h-full bg-primary flex flex-col justify-start admin-sidebar py-4">
            <Link
                className="flex gap-5 my-10 items-center justify-center"
                to="/admin/profile"
            >
                <img width="65" height="65" src={adminLogo} alt="admin-logo" />
            </Link>

            <nav>
                <ul className="sidebar--nav-menu text-white ">
                    <NavItem
                        classes={adminClasses}
                        path="/admin/payment"
                        text="Πληρωμές Οφειλών"
                    />
                    <NavItem
                        classes={adminClasses}
                        path="/admin/balance-request"
                        text="Αιτήσεις Υπολοίπου"
                    />
                    <NavItem
                        classes={adminClasses}
                        path="/admin/municipal-awareness-request"
                        text="Δημοτική Ενημερότητα"
                    />
                    <NavItem
                        classes={adminClasses}
                        path="/admin/kok"
                        text="Κλήσεις Κ.Ο.Κ."
                    />
                </ul>
            </nav>
        </aside>
    ) : (
        <aside className="h-full flex flex-col justify-start pr-14 w-1/4 ">
            <Link to="/">
                <img width="80" height="80" src={logo} alt="logo" />
            </Link>

            <nav>
                <ul className="">
                    <NavItem
                        classes={emailVerifed ? userClasses : disabledClasses}
                        hasSubmenu={true}
                        text="Οι οφειλές μου"
                        subMenuItems={[
                            {
                                text: 'Πληρωμή Οφειλής',
                                path: '/payment',
                            },
                            {
                                text: 'Βεβαιωμένες Οφειλές',
                                path: '/debts/confirmed',
                            },
                            {
                                text: 'Οφειλές σε Ρύθμιση',
                                path: '/debts/installments',
                            },
                            {
                                text: 'Οφειλές από Αίτηση Υπολοίπου',
                                path: '/balance-request-debts',
                            },
                            {
                                text: 'Ιστορικό Πληρωμών',
                                path: '/payments',
                            },
                        ]}
                    />

                    <NavItem
                        classes={emailVerifed ? userClasses : disabledClasses}
                        hasSubmenu={true}
                        text="Κλήσεις Κ.Ο.Κ"
                        subMenuItems={[
                            {
                                text: 'Οι κλήσεις μου',
                                path: '/fines',
                            },
                            {
                                text: 'Ιστορικό Κλήσεων',
                                path: '/fines-history',
                            },
                        ]}
                    />

                    <NavItem
                        classes={emailVerifed ? userClasses : disabledClasses}
                        path="/requests"
                        text="Αίτημα - Βεβαίωση"
                    />
                </ul>
            </nav>
        </aside>
    )
}

Sidebar.propTypes = {
    forAdmin: PropTypes.bool,
}

export default Sidebar
