import admin404 from '../../assets/images/admin404.png'

import { useEffect } from 'react'

const AdminPage404 = () => {
    useEffect(() => {
        document.title = 'Η σελίδα δεν βρέθηκε'
    }, [])

    return (
        <div>
            <img src={admin404} className="pb-10" alt="Page not found" />
        </div>
    )
}

export default AdminPage404
