import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { useEffect } from 'react'

const TermsAndConditions = () => {
    useEffect(() => {
        document.title =
            'Όροι χρήσης και πολιτική απορρήτου - Πλατφόρμα Ηλ. Πληρωμών Δήμου Δυτικής Λέσβου'
    }, [])

    return (
        <>
            <Header includeLogo={true} includeButtons={true} />

            <main className="container mx-auto my-10 px-4 md:px-0">
                <h1 className="text-center text-2xl font-bold mb-8">
                    Όροι χρήσης και πολιτική απορρήτου
                </h1>

                <p>
                    Ο Ευρωπαϊκός Γενικός Κανονισμός Προστασίας Δεδομένων (GDPR –
                    ΕΕ 2016/679) τέθηκε σε πλήρη εφαρμογή στις 25 Μαΐου 2018.
                    Στο πλαίσιο αυτό, ο Δήμος Δυτικής Λέσβου έχει αναπτύξει
                    Πολιτική για την Προστασία των Δεδομένων Προσωπικού
                    Χαρακτήρα, προκειμένου να εξασφαλιστεί πλήρως η προστασία
                    των δημοτών, των εργαζομένων, των συνεργατών και άλλων
                    ενδιαφερόμενων μερών. Η Πολιτική αφορά στην Προστασία των
                    Προσωπικών Δεδομένων, ώστε να ικανοποιούνται οι προϋποθέσεις
                    που θέτει ο Ευρωπαϊκός Κανονισμός Προστασίας Δεδομένων (GDPR
                    – ΕΕ 2016/679).
                </p>

                <h2 className="font-bold my-4">
                    1. Διαχείριση και προστασία δεδομένων προσωπικού χαρακτήρα
                </h2>

                <p>
                    Ο Δήμος Δυτικής Λέσβου διαχειρίζεται προσωπικά δεδομένα τα
                    οποία:
                </p>

                <ul className="list-disc pl-8">
                    <li>
                        τίθενται σε επεξεργασία με νόμιμο, δίκαιο και διαφανή
                        τρόπο
                    </li>
                    <li>
                        συλλέγονται αποκλειστικά για συγκεκριμένους και νόμιμους
                        σκοπούς είναι επαρκή, σχετίζονται με τον σκοπό για τον
                        οποίο συλλέγονται και περιορίζονται στα απαραίτητα
                    </li>

                    <li>είναι εξακριβωμένα</li>

                    <li>
                        διατηρούνται αποκλειστικά εντός του καθορισμένου
                        χρονικού πλαισίου και όχι περισσότερο
                    </li>

                    <li>
                        τίθενται σε επεξεργασία με τέτοιο τρόπο ώστε να
                        εξασφαλίζεται η απαραίτητη ασφάλεια των δημοτών.
                    </li>
                </ul>

                <h2 className="font-bold my-4">2. Δικαιώματα χρηστών</h2>

                <p>
                    Ο Δήμος Δυτικής Λέσβου διασφαλίζει τα δικαιώματα των
                    δημοτών, των εργαζομένων, των συνεργατών και άλλων
                    ενδιαφερόμενων μερών σχετικά με την επεξεργασία των
                    προσωπικών δεδομένων τους και φροντίζει τη διευκόλυνση της
                    άσκησης των δικαιωμάτων τους. Οι χρήστες έχουν δικαίωμα να
                    ζητήσουν:
                </p>

                <ol className="list-decimal pl-8">
                    <li>
                        πρόσβαση στα προσωπικά τους δεδομένα και πληροφορίες
                        σχετικά με το ποια δεδομένα τους επεξεργάζονται, τους
                        σκοπούς της επεξεργασίας, τους αποδέκτες και τη διάρκεια
                        της επεξεργασίας,
                    </li>

                    <li>
                        διόρθωση των προσωπικών τους δεδομένων σε περίπτωση που
                        είναι ανακριβή ή ελλιπή,
                    </li>

                    <li>
                        διαγραφή των προσωπικών τους δεδομένων, εφόσον
                        συντρέχουν οι νόμιμες προϋποθέσεις,
                    </li>

                    <li>
                        περιορισμό της επεξεργασίας των προσωπικών τους
                        δεδομένων μόνο για συγκεκριμένους σκοπούς,
                    </li>

                    <li>
                        να ανακαλέσουν οποιαδήποτε στιγμή τη συγκατάθεσή τους
                        για την επεξεργασία των προσωπικών τους δεδομένων. Στην
                        περίπτωση αυτή, θα διακοπεί η επεξεργασία τους από το
                        Δήμο Δυτικής Λέσβου, χωρίς αυτό να επηρεάζει τη
                        νομιμότητα κάθε επεξεργασίας μέχρι την ανάκληση της
                        συγκατάθεσής τους.
                    </li>
                </ol>

                <p className="mt-8">
                    Σε περίπτωση παραβίασης των δικαιωμάτων τους οι χρήστες
                    έχουν το δικαίωμα να προσφύγουν στην Αρχή Προστασίας
                    Δεδομένων Προσωπικού Χαρακτήρα (
                    <a
                        href="www.dpa.gr"
                        target="_blank"
                        rel="noreferrer"
                        className="text-darkBlue"
                    >
                        www.dpa.gr
                    </a>
                    , 2106475600).
                </p>

                <h2 className="font-bold my-4">3. Στοιχεία που συλλέγονται</h2>

                <ul className="list-disc pl-8">
                    <li>e-mail</li>
                    <li>ΑΦΜ</li>
                    <li>Επώνυμο</li>
                    <li>Όνομα</li>
                    <li>Πατρώνυμο</li>
                    <li>Μητρώνυμο</li>
                    <li>Έτος γέννησης</li>
                    <li>Ημ/νια Εγγραφής</li>
                    <li>Πόλη/ Οικισμός / Χωριό</li>
                    <li>Ταχ. Κωδ.</li>
                    <li>Περιοχή / Νομός</li>
                    <li>Διευθύνσεις</li>
                    <li>Ρόλος</li>
                    <li>Οφειλές</li>
                    <li>Ιστορικών Πληρωμών</li>
                    <li>Δημοτική Ενημερότητα</li>
                    <li>Αίτησης Υπολοίπου</li>
                    <li>Τηλέφωνο (εφόσον ζητηθεί από το Δήμο)</li>
                    <li>Όνομα web- χρήστη</li>
                    <li>Όνομα/ επώνυμο συνδρομητή</li>
                    <li>Αρ. Μητρώου συνδρομητή</li>
                    <li>Έναρξη - Λήξη σύνδεσης (Ημερομηνία & ώρα)</li>
                    <li>Διάρκεια σύνδεσης</li>
                    <li>Στοιχεία οικονομικού συναλλασσόμενου</li>
                </ul>

                <h2 className="font-bold my-4">4. Επικοινωνία</h2>

                <p>
                    Για ερωτήματα σχετικά με την προστασία των δεδομένων
                    προσωπικού χαρακτήρα, μπορείτε να στείλετε email μέσω της
                    ιστοσελίδας του Δήμου Δυτικής Λέσβου, τα οποία θα απαντηθούν
                    το συντομότερο δυνατόν και όχι αργότερα από ένα μήνα.
                </p>

                <p className="mt-4">
                    Δείτε την{' '}
                    <a
                        href="/GDPR.pdf"
                        target="_blank"
                        className="text-darkBlue"
                    >
                        Πολιτική Προστασίας Δεδομένων Προσωπικού Χαρακτήρα.
                    </a>
                </p>
            </main>

            <Footer />
        </>
    )
}

export default TermsAndConditions
