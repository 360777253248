import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const Dialog = forwardRef(function Dialog({ children }, ref) {
    return (
        <dialog
            ref={ref}
            className="rounded-3xl flex flex-col px-5 py-10 max-w-lg"
        >
            {children}
        </dialog>
    )
})

Dialog.propTypes = {
    children: PropTypes.array,
}

export default Dialog
