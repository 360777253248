import './Footer.css'

import cpLogo from '../../assets/images/cp-logo.svg'
import crowdapps from '../../assets/images/crowdapps.svg'
import PropTypes from 'prop-types'
import espa from '../../assets/images/espa-logo.png'

const Footer = ({ inMenu = false }) => {
    return (
        <footer className={`${inMenu ? 'fixed w-full bottom-0' : ''} bg-white`}>
            <img
                className="w-full max-w-[80%] md:max-w-[450px] mx-auto my-5"
                src={espa}
                alt="espa"
            />
            <div className="container mx-auto flex flex-col lg:flex-row justify-center items-center gap-3 py-3.5">
                <a
                    href="https://hello.crowdapps.net/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary text-center flex flex-col md:flex-row items-center gap-2"
                >
                    <img
                        width="65"
                        height="30"
                        loading="lazy"
                        src={crowdapps}
                        alt="Crowdapps"
                    />
                </a>
                {`Copyright ${new Date().getFullYear()}`}
                <div className="footer--separator hidden lg:block"></div>
                <a
                    href="/terms-and-conditions"
                    rel="noreferrer"
                    target="_blank"
                    className="text-primary"
                >
                    Πολιτική Απορρήτου
                </a>

                <div className="footer--separator hidden lg:block"></div>

                <a
                    href="https://www.crowdpolicy.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary text-center flex flex-col md:flex-row items-center gap-2"
                >
                    Σχεδιασμός και υλοποίηση από την Crowdpolicy
                    <img
                        width="65"
                        height="30"
                        loading="lazy"
                        src={cpLogo}
                        alt="Crowdpolicy"
                    />
                </a>
            </div>
        </footer>
    )
}

Footer.propTypes = {
    inMenu: PropTypes.bool,
}

export default Footer
