export function getRoleText(role) {
    switch (role) {
        case 'ADMIN':
            return 'Διαχειριστής'
        case 'COMPANY':
            return 'Επιχείρηση'
        case 'CITIZEN':
            return 'Δημότης'
        default:
            break
    }
}
