import imageToBase64 from 'image-to-base64/browser'

export function formatDate(UTCDate, dueDate = false) {
    try {
        const date = new Date(UTCDate)
        if (dueDate) {
            return new Intl.DateTimeFormat('el-GR', {
                month: '2-digit',
                day: '2-digit',
                year: '2-digit',
                hour12: false,
            }).format(date)
        }
        return new Intl.DateTimeFormat('el-GR', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        }).format(date)
    } catch (err) {
        console.error(err)
        return '---'
    }
}

export function formatAmount(amount) {
    try {
        return new Intl.NumberFormat('el-GR', {
            style: 'currency',
            currency: 'EUR',
        }).format(amount)
    } catch (err) {
        console.error(err)
        return '---'
    }
}

export async function imgToBase64(imagePath) {
    try {
        const imgToBase64Module = await imageToBase64(imagePath)
        const convertedImage = `data:image/png;base64,${imgToBase64Module}`
        return convertedImage
    } catch (error) {
        console.error(error)
    }
}

export function debtsPaymentDescription(str, ids) {
    return str + ':' + ids.join(',')
}

export function govpayDateFormat(string) {
    const date = new Date(string)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    return `${year}-${month}-${day}`
}

export function sortByDate(array, index) {
    const sortedArray = [...array].sort((a, b) => {
        const [dayA, monthA, yearA, timeA] = a[index].split(/[/, ]+/)
        const [dayB, monthB, yearB, timeB] = b[index].split(/[/, ]+/)

        const dateA = new Date(`${yearA}-${monthA}-${dayA}T${timeA}`)
        const dateB = new Date(`${yearB}-${monthB}-${dayB}T${timeB}`)

        if (isNaN(dateA.getTime())) {
            return -1 // Move invalid dates to the end
        }
        if (isNaN(dateB.getTime())) {
            return 1 // Move invalid dates to the end
        }

        return dateA.getTime() - dateB.getTime()
    })

    return sortedArray
}
