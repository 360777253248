import { createContext, useEffect, useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const AuthContext = createContext()

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null)
    const [error, setError] = useState()
    const [loading, setLoading] = useState(true)

    const location = useLocation()

    const validateToken = async token => {
        try {
            const res = await fetch(
                `${process.env.REACT_APP_API_URL}/users/me`,
                {
                    headers: {
                        Authorization: `Bearer ${token.access_token}`,
                    },
                }
            )

            return res.status === 200
        } catch (err) {
            console.error(err)
            return false
        }
    }

    useEffect(() => {
        // Reset the error state when page changes
        if (error) {
            setError(null)
        }

        /**
         * Check if there is a currently active session
         * each time the page changes.
         *
         * If there is an error, it means there is no session.
         *
         * Finally, just signal the component that the initial load
         * is over.
         */
        ;(async () => {
            try {
                const tokenFromStorage = sessionStorage.getItem('govpay-jwt')

                // Bail out if there's no token in state and no token in sessionStorage
                if (!token && !tokenFromStorage) {
                    return
                }

                const tokenStringified = JSON.stringify(token)

                // Bail out if the token from storage and the token from state are equal
                if (tokenStringified === tokenFromStorage) {
                    return
                }

                // Otherwise check if the token from session is a valid token with a request to the backend
                const parsedTokenValue = JSON.parse(tokenFromStorage)
                const isTokenValid = await validateToken(parsedTokenValue)

                if (!isTokenValid) {
                    setToken(null)
                    return
                }

                setToken(parsedTokenValue)
            } catch (err) {
                console.error(err)
                setError(err)
            } finally {
                setLoading(false)
            }
        })()
    }, [location.pathname])

    /**
     * Make the provider update only when it should.
     * We only want to force re-renders if the user,
     * loading or error states change.
     *
     * Whenever the `value` passed into a provider changes,
     * the whole tree under the provider re-renders, and
     * that can be very costly! Even in this case, where
     * you only get re-renders when logging in and out
     * we want to keep things very performant.
     */
    const memoedValue = useMemo(
        () => ({
            token,
            setToken,
            error,
        }),
        [token, error]
    )

    // We only want to render the underlying app after we assert for the presence of a current user.
    return (
        <AuthContext.Provider value={memoedValue}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
