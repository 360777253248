import './index.css'

import ReactDOM from 'react-dom/client'
import React from 'react'
import Roles from './enums/Roles'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { AuthProvider } from './contexts/AuthContext'

// Routes
import Home from './routes/Home'
import Login from './routes/Login'
import Index from './routes/Index'
import TermsAndConditions from './routes/TermsAndConfitions'

// User routes
import Profile from './routes/Profile'
import Payment from './routes/Payment'
import PaymentSuccess from './routes/PaymentSuccess'
import PaymentHistory from './routes/PaymentHistory'
import Requests from './routes/Requests'
import Page404 from './routes/Page404'
import BalanceRequestDetails from './routes/BalanceRequestDetails'
import MunicipalAwarenessRequestMore from './routes/MunicipalAwarenessRequestDetails'
import PaymentFailure from './routes/PaymentFailure'
import PaymentDetails from './routes/PaymentDetails'
import EmailVerification from './routes/EmailVerification'
import DebtsInstallments from './routes/DebtsInstallments'
import DebtsConfirmed from './routes/DebtsConfirmed'
import UnderConstruction from './routes/UnderConstruction'
import BalanceRequestDebts from './routes/BalanceRequestDebts'
import Fines from './routes/Fines'
import FinesHistory from './routes/FinesHistory'

// Admin routes
import Admin from './routes/admin/Admin'
import AdminProfile from './routes/admin/Profile'
import AdminHome from './routes/admin/Home'
import AdminLogin from './routes/admin/Login'
import AdminPayment from './routes/admin/Payment'
import AdminPaymentEdit from './routes/admin/PaymentEdit'
import AdminBalanceRequest from './routes/admin/BalanceRequest'
import AdminBalanceRequestEdit from './routes/admin/BalanceRequestEdit'
import AdminMunicipalAwarenessRequest from './routes/admin/MunicipalAwarenessRequest'
import AdminMunicipalAwarenessRequestEdit from './routes/admin/MunicipalAwarenessRequestEdit'
import AdminPage404 from './routes/admin/Page404'
import Kok from './routes/admin/Kok'
import KokCreation from './routes/admin/KokCreation'
import KokFineEdit from './routes/admin/KokFineEdit'
import Register from './routes/Register'

const root = ReactDOM.createRoot(document.getElementById('root'))

const Router = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/under-construction" element={<UnderConstruction />} />
            <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
            />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="register" element={<Register />} />

            {/* User nested routes */}
            <Route path="/" element={<Index />}>
                <Route index element={<Home />} />
                <Route
                    path="auth/mail-verify/:vToken"
                    element={<EmailVerification />}
                />
                <Route
                    path="profile"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <Profile />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="payment"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <Payment />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="payment/success"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <PaymentSuccess />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="payment/failure"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <PaymentFailure />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="debts/confirmed"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <DebtsConfirmed />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="debts/installments"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <DebtsInstallments />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="payments"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <PaymentHistory />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="payments/:id"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <PaymentDetails />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="requests"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <Requests />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="balance-requests/:id"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <BalanceRequestDetails />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="municipal-awareness-requests/:id"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <MunicipalAwarenessRequestMore />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="balance-request-debts"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <BalanceRequestDebts />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="fines"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <Fines />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="fines-history"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <FinesHistory />
                        </ProtectedRoute>
                    }
                />

                <Route path="/*" element={<Page404 />} />
            </Route>

            {/* Admin nested routes */}
            <Route
                path="/admin"
                element={
                    <ProtectedRoute allowedRoles={[Roles.admin]}>
                        <Admin />
                    </ProtectedRoute>
                }
            >
                <Route index element={<AdminHome />} />
                <Route path="profile" element={<AdminProfile />} />
                <Route path="payment" element={<AdminPayment />} />
                <Route path="payment/:id" element={<AdminPaymentEdit />} />
                <Route
                    path="balance-request"
                    element={<AdminBalanceRequest />}
                />
                <Route
                    path="balance-request/:id"
                    element={<AdminBalanceRequestEdit />}
                />
                <Route
                    path="municipal-awareness-request"
                    element={<AdminMunicipalAwarenessRequest />}
                />
                <Route
                    path="municipal-awareness-request/:id"
                    element={<AdminMunicipalAwarenessRequestEdit />}
                />
                <Route path="kok" element={<Kok />} />
                <Route path="kok/:id" element={<KokFineEdit />} />
                <Route path="kok/add" element={<KokCreation />} />
                <Route path="/admin/*" element={<AdminPage404 />} />
            </Route>
        </Routes>
    )
}

const queryClient = new QueryClient()

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                scriptProps={{
                    language: 'el',
                    appendTo: 'head',
                    async: false,
                    defer: false,
                }}
            >
                <AuthProvider>
                    <QueryClientProvider client={queryClient}>
                        <Router />
                    </QueryClientProvider>
                </AuthProvider>
            </GoogleReCaptchaProvider>
        </BrowserRouter>
    </React.StrictMode>
)
