import './HomeLoggedOut.css'

import balance from '../../assets/images/upoloipo.svg'
import payment from '../../assets/images/ofeili.svg'
import kok from '../../assets/images/kok.svg'

import card from '../../assets/images/card.svg'
import pos from '../../assets/images/pos.svg'
import euro from '../../assets/images/euro.svg'
import cardEuro from '../../assets/images/card-euro.svg'

import { Link } from 'react-router-dom'

const TopPart = () => {
    return (
        <>
            <div className="w-full flex homeLoggedOut">
                <div className="w-1/6 h-full flex-auto px-8 my-1 boxes-left relative box-column">
                    <img
                        className="card-euro absolute z-10"
                        src={cardEuro}
                        alt="card-euro"
                    />
                    <img className="pos absolute" src={pos} alt="pos" />
                </div>
                <div className="w-1/3 flex-auto px-8 my-10 boxes-center">
                    <div className="flex flex-col h-full justify-center">
                        <p className="text-center py-14 font-bold z-10 text-[#2C3C46]">
                            Η ηλεκτρονική υπηρεσία GovPay απευθύνεται σε πολίτες
                            και επιχειρήσεις του Δήμου Δυτικής Λέσβου και έχει
                            ως στόχο την απλοποίηση των διαδικασιών σε μια σειρά
                            θεμάτων πληρωμών που σχετίζονται με το Δήμο, όπως
                            είναι το παράβολο πολιτικού γάμου, τα τέλη άρδευσης
                            κ.ά.
                        </p>
                        <div className="flex flex-col gap-4">
                            <div className="w-full flex gap-14  my-12 md:justify-center">
                                <div className="w-1/2 md:w-max">
                                    <div className="box h-full p-8 bg-white text-center ">
                                        <Link to="/payment">
                                            <h2 className="text-3xl font-bold text-accent">
                                                Πληρωμή Οφειλής
                                            </h2>
                                            <img
                                                className="max-h-32 mt-10 mx-auto"
                                                src={payment}
                                                alt="ofeili"
                                            />
                                        </Link>
                                    </div>
                                </div>
                                <div className="w-1/2 md:w-max">
                                    <div className="box p-8 bg-white text-center ">
                                        <Link to="/balance-request-debts">
                                            <h2 className="text-3xl font-bold text-accent">
                                                Αίτηση υπολοίπου
                                            </h2>
                                            <img
                                                className="max-h-32 mt-10 mx-auto"
                                                src={balance}
                                                alt="balance"
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="box w-full max-w-[450px] p-8 bg-white text-center self-center">
                                <Link
                                    to="/fines"
                                    className="flex flex-col lg:flex-row items-center gap-3"
                                >
                                    <h2 className="text-3xl lg:order-2 font-bold text-accent">
                                        Κλήσεις Κ.Ο.Κ.
                                    </h2>
                                    <img
                                        className="max-h-32 lg:order-1"
                                        src={kok}
                                        alt="instant"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-1/6 h-full flex-auto px-8 my-1 boxes-right relative box-column">
                    <img className="euro absolute" src={euro} alt="euro" />
                    <img className="card absolute" src={card} alt="card" />
                </div>
            </div>
        </>
    )
}

export default TopPart
