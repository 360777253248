import Modal from '../components/Modal/Modal'
import Header from '../components/Header/Header'
import HistoryTable from '../components/HistoryTable/HistoryTable'
import PopupMessage from '../components/PopupMessage/PopupMessage'
import Loading from '../components/Loading/Loading'
import StatusText from '../components/StatusText/StatusText'
import HistoryItem from '../components/mobile-components/HistoryItem/HistoryItem'
import MoreButton from '../components/TableButtons/MoreButton'

import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import { formatDate, sortByDate } from '../helpers/formatters'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import useAuth from '../hooks/useAuth'
import useMediaQuery from '../hooks/useMediaQuery'
import {
    getBalanceRequestsOfUser,
    createBalanceRequest,
} from '../api/balance-requests'
import {
    createMunicipalAwarenessRequest,
    getMunicipalAwarenessRequestsOfUser,
} from '../api/municipal-awareness-requests'
import Announcement from '../components/Announcement/Announcement'

const Requests = () => {
    useEffect(() => {
        document.title =
            'Αιτήματα - Πλατφόρμα Ηλ. Πληρωμών Δήμου Δυτικής Λέσβου'
    }, [])

    const isMobile = useMediaQuery('(max-width:1024px)')
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })
    const { token } = useContext(AuthContext)
    const { getCurrentUserID } = useAuth()
    const userId = getCurrentUserID()
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }
    const queryClient = useQueryClient()

    const { isLoading: isLoadingBalanceRequests, data: dataBalanceRequests } =
        useQuery({
            queryKey: ['balanceRequestsHistory', userId, options],
            queryFn: () => getBalanceRequestsOfUser(userId, options),
        })
    const {
        isLoading: isLoadingMunicipalAwarenessRequests,
        data: dataMunicipalAwarenessRequests,
    } = useQuery({
        queryKey: ['municipalAwarenessRequestsHistory', userId, options],
        queryFn: () => getMunicipalAwarenessRequestsOfUser(userId, options),
    })

    const mutationBalanceRequest = useMutation({
        mutationFn: () =>
            createBalanceRequest({
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                },
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['balanceRequestsHistory'],
            })

            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <p>
                        Η αίτηση σας έχει υποβληθεί. Θα ενημερωθείτε σύντομα για
                        την εξέλιξη της με σχετικό e-mail, καθώς και από τον
                        πίνακα &nbsp;
                        {/* Add space with html entity because linter does not let us add with text */}
                        <Link className="font-bold" to={'/balance-requests'}>
                            &quot;Ιστορικό Αιτήσεων&quot;
                        </Link>
                    </p>
                ),
                type: 'success',
            }))
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Η αίτησή σας δεν υποβλήθηκε.</p>
                        <p>Παρακαλώ προσπαθήστε ξανά</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    const mutationMunicipalAwarenessRequest = useMutation({
        mutationFn: () =>
            createMunicipalAwarenessRequest({
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                },
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['municipalAwarenessRequestsHistory'],
            })

            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <p>
                        Η αίτηση σας έχει υποβληθεί. Θα ενημερωθείτε σύντομα για
                        την εξέλιξη της με σχετικό e-mail, καθώς και από τον
                        πίνακα &nbsp;
                        {/* Add space with html entity because linter does not let us add with text */}
                        <Link className="font-bold" to={'/balance-requests'}>
                            &quot;Ιστορικό Αιτήσεων&quot;
                        </Link>
                    </p>
                ),
                type: 'success',
            }))
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Η αίτησή σας δεν υποβλήθηκε.</p>
                        <p>Παρακαλώ προσπαθήστε ξανά</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    if (isLoadingBalanceRequests || isLoadingMunicipalAwarenessRequests) {
        return <Loading />
    }

    const headings = [
        'Κατηγορία Αίτησης',
        'Ημερομηνία Υποβολής Αίτησης',
        'Κατάσταση',
        'Περισσότερα',
    ]

    const balanceRequestRows = dataBalanceRequests?.balanceRequests.map(
        request => {
            return {
                0: 'Αίτηση Υπολοίπου',
                1: formatDate(request.createdAt) ?? '-',
                2: (
                    <StatusText
                        key={3}
                        kind="request"
                        status={request.status}
                    />
                ),
                3: (
                    <MoreButton
                        key={4}
                        kind={'balance-requests'}
                        requestID={request.id}
                    />
                ),
            }
        }
    )

    const municipalAwarenessRequestRows =
        dataMunicipalAwarenessRequests?.municipalAwarenessRequests.map(
            request => {
                return {
                    0: 'Δημοτική Ενημερότητα',
                    1: formatDate(request.createdAt) ?? '-',
                    2: (
                        <StatusText
                            key={3}
                            kind="request"
                            status={request.status}
                        />
                    ),
                    3: (
                        <MoreButton
                            key={4}
                            kind={'municipal-awareness-requests'}
                            requestID={request.id}
                        />
                    ),
                }
            }
        )

    // Sort rows with time value
    const sortedRows = sortByDate(
        [...balanceRequestRows, ...municipalAwarenessRequestRows],
        1
    )

    const mainContent = (
        <>
            <h2 className="text-primary text-3xl font-light mt-12 mb-6">
                Αίτημα - Βεβαίωση
            </h2>

            <Announcement
                date="22/4/2024"
                title="Aνακοίνωση - Απάντηση Αιτήματος Απριλίου"
                content={
                    <>
                        <p className="text-gray-700 mb-4">
                            Στην σελίδα (
                            <a
                                href="https://www.mwlesvos.gr/tilefonikos-katalogos/"
                                className="text-blue-500 hover:underline text-[#61703f] font-bold"
                                target="_blank"
                                rel="noreferrer"
                            >
                                αυτή
                            </a>
                            ) μπορείτε να βρείτε τα σχετικά στοιχεία
                            επικοινωνίας των υπηρεσιών που αφορούν το αίτημά σας
                            και να προσκομίσετε τυχον δικαιολογητικά
                            ταυτοποίησης που θα ζητηθούν. Επιπλέον: 2253 3 51
                            510 - esoda@mwlesvos.gr
                        </p>
                    </>
                }
            />

            <div className="flex justify-between items-center gap-12 py-8 px-10 shadow-lg rounded-2xl">
                <p className="font-medium text-base text-primary">
                    Μπορείς να ζητήσεις ενημέρωση για το υπόλοιπό σου με ένα
                    κλικ!
                </p>

                <button
                    className="bg-olive hover:bg-white text-white hover:text-olive border border-olive transition-all font-bold rounded-lg py-3.5 px-5"
                    onClick={() => mutationBalanceRequest.mutate()}
                    disabled={mutationBalanceRequest.isLoading}
                >
                    Αίτηση Υπολοίπου
                </button>
            </div>

            <div className="flex justify-between items-center gap-12 py-8 px-10 shadow-lg rounded-2xl">
                <p className="font-medium text-base text-primary">
                    Μπορείς να ζητήσεις τη δημοτική σου ενημερότητα με ένα κλικ!
                </p>

                <button
                    className="bg-olive hover:bg-white text-white hover:text-olive border border-olive transition-all font-bold rounded-lg py-2.5 px-2"
                    onClick={() => mutationMunicipalAwarenessRequest.mutate()}
                    disabled={mutationMunicipalAwarenessRequest.isLoading}
                >
                    Αίτηση Δημοτικής Ενημερότητας
                </button>
            </div>
        </>
    )

    return (
        <>
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                />
            )}

            {isMobile ? (
                <>
                    {mainContent}

                    <h2 className="text-primary text-3xl font-light mt-24 mb-6">
                        Ιστορικό Αιτήσεων
                    </h2>

                    {dataBalanceRequests.balanceRequests.length > 0 ? (
                        dataBalanceRequests.balanceRequests.map(
                            (request, index) => {
                                return (
                                    <HistoryItem
                                        key={request.id}
                                        headings={headings}
                                        rows={balanceRequestRows[index]}
                                    />
                                )
                            }
                        )
                    ) : (
                        <h2 className="text-base text-primary font-bold text-center">
                            Δεν βρέθηκαν αιτήσεις υπολοίπου
                        </h2>
                    )}

                    {dataMunicipalAwarenessRequests.municipalAwarenessRequests
                        .length > 0 ? (
                        dataMunicipalAwarenessRequests.municipalAwarenessRequests.map(
                            (request, index) => {
                                return (
                                    <HistoryItem
                                        key={request.id}
                                        headings={headings}
                                        rows={
                                            municipalAwarenessRequestRows[index]
                                        }
                                    />
                                )
                            }
                        )
                    ) : (
                        <h2 className="text-base text-primary font-bold text-center">
                            Δεν βρέθηκαν αιτήσεις δημοτικής ενημερότητας
                        </h2>
                    )}
                </>
            ) : (
                <Modal classes="w-full max-w-7xl flex flex-col">
                    <Header
                        includeLogo={false}
                        withBorder={true}
                        includeButtons={true}
                    />

                    <div className="flex flex-col justify-start items-center grow p-6">
                        {mainContent}

                        <h2 className="text-primary text-3xl font-light mt-24 mb-6">
                            Ιστορικό Αιτήσεων
                        </h2>

                        <HistoryTable
                            headings={headings}
                            rows={sortedRows}
                            noDataText="Δεν βρέθηκαν αιτήσεις"
                        />
                    </div>
                </Modal>
            )}
        </>
    )
}

export default Requests
