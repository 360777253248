import PropTypes from 'prop-types'
import more from '../../assets/images/more.svg'
import { Link } from 'react-router-dom'

const MoreButton = ({ requestID, kind }) => {
    return (
        <Link to={`/${kind}/${requestID}`} id={requestID}>
            <img
                className="mx-auto"
                width="32"
                height="32"
                src={more}
                alt="more-button"
            />
        </Link>
    )
}

MoreButton.propTypes = {
    requestID: PropTypes.number.isRequired,
    kind: PropTypes.oneOf(['payments', 'balance-requests']),
}

export default MoreButton
