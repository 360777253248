import HistoryTable from '../../components/HistoryTable/HistoryTable'
import StatusText from '../../components/StatusText/StatusText'
import TableFilters from '../../components/TableFilters/TableFilters'
import Loading from '../../components/Loading/Loading'
import HistoryItem from '../../components/mobile-components/HistoryItem/HistoryItem'
import MobileTableFilters from '../../components/TableFilters/MobileTableFilters'
import EditButton from '../../components/TableButtons/EditButton'

import { useState, useEffect, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AuthContext } from '../../contexts/AuthContext'
import { formatDate, formatAmount } from '../../helpers/formatters'
import { getAllFines } from '../../api/kok-fines'
import useMediaQuery from '../../hooks/useMediaQuery'
import { Link } from 'react-router-dom'

const KokRequests = () => {
    useEffect(() => {
        document.title =
            'Διαχείριση Κλήσεων Κ.Ο.Κ. - Πλατφόρμα Ηλ. Πληρωμών Δήμου Δυτικής Λέσβου'
    }, [])

    const isMobile = useMediaQuery('(max-width:1024px)')
    const [filterValues, setFilterValues] = useState({
        afm: '',
        title: '',
        status: '',
    })
    const [showFilters, setShowFilters] = useState(false)
    const { token } = useContext(AuthContext)
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }

    const handleFilterChange = newFilters => {
        setFilterValues(newFilters)
    }

    const { isLoading, data: kokRequests } = useQuery({
        queryKey: ['adminKokFines', options, filterValues],
        queryFn: () => getAllFines(filterValues, options),
    })

    const rows = kokRequests?.data?.map(request => {
        return {
            0: request.user.profile.taxid ?? '-',
            1:
                request.user.profile.firstName && request.user.profile.lastName
                    ? `${request.user.profile.firstName} ${request.user.profile.lastName}`
                    : '-',
            2: formatDate(request.createdAt) ?? '-',
            3: request.fineNumber ?? '-',
            4: request.vehicleNumber ?? '-',
            5: formatAmount(request.amount) ?? '-',
            6: formatDate(request.updatedAt) ?? '-',
            7: (
                <StatusText
                    key={request.id}
                    kind="kok"
                    status={request.status}
                />
            ),
            8: <EditButton key={request.id} id={request.id} kind="kok" />,
        }
    })

    const headings = [
        'ΑΦΜ',
        'Ονοματεπώνυμο',
        'Ημερομηνία Κλήσης',
        'Αριθμός κλήσης',
        'Αριθμός Κυκλοφορίας',
        'Ποσό',
        'Ημερομηνία Υποβολής',
        'Κατάσταση',
        'Επεξεργασία',
    ]

    // TODO: Fix mobile filters functionality !
    if (showFilters) {
        return (
            <MobileTableFilters
                kind="balance-request"
                toggleFilters={() => setShowFilters(!showFilters)}
                setFilterValues={handleFilterChange}
            />
        )
    }

    return (
        <div className="flex flex-col items-center m-10">
            <h2 className="text-primary text-3xl text-center font-light mt-6 md:mt-10 mb-6">
                Κλήσεις Κ.Ο.Κ.
            </h2>

            <Link
                className="my-2 bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all rounded-lg w-fit py-1.5 px-10"
                to="/admin/kok/add/"
            >
                Προσθήκη Νέας
            </Link>

            {isMobile ? /*
             * <button
             *     onClick={() => setShowFilters(!showFilters)}
             *     className="self-auto bg-blue text-white border border-blue hover:bg-white hover:text-blue rounded-lg py-3.5 px-10 transition-all"
             * >
             *     Φίλτρα
             * </button>
             */ null : (
                <TableFilters
                    kind="balance-request"
                    filterValues={filterValues}
                    handleFilterChange={handleFilterChange}
                />
            )}
            {isLoading ? (
                <div className="pt-24">
                    <Loading />
                </div>
            ) : isMobile ? (
                <>
                    {kokRequests.data.length > 0 ? (
                        kokRequests.data.map((request, index) => {
                            return (
                                <HistoryItem
                                    key={request.id}
                                    headings={headings}
                                    rows={rows[index]}
                                />
                            )
                        })
                    ) : (
                        <h2 className="text-base text-primary font-bold text-center py-20">
                            Δεν βρέθηκαν αιτήσεις
                        </h2>
                    )}
                </>
            ) : (
                <div className="my-10 w-full flex justify-center">
                    <HistoryTable
                        headings={headings}
                        rows={rows}
                        noDataText="Δεν βρέθηκαν αιτήσεις"
                    />
                </div>
            )}
        </div>
    )
}

export default KokRequests
