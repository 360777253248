import Modal from '../components/Modal/Modal'
import Header from '../components/Header/Header'
import HistoryTable from '../components/HistoryTable/HistoryTable'
import StatusText from '../components/StatusText/StatusText'
import Loading from '../components/Loading/Loading'
import HistoryItem from '../components/mobile-components/HistoryItem/HistoryItem'
import MoreButton from '../components/TableButtons/MoreButton'
import DownloadButton from '../components/TableButtons/DownloadButton'

import { useEffect, useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { formatAmount, formatDate, sortByDate } from '../helpers/formatters'
import { useQuery } from '@tanstack/react-query'
import { getPaymentsOfUser } from '../api/payments'

import useAuth from '../hooks/useAuth'
import useMediaQuery from '../hooks/useMediaQuery'
import Status from '../enums/Status'

const PaymentHistory = () => {
    useEffect(() => {
        document.title =
            'Ιστορικό Πληρωμών - Πλατφόρμα Ηλ. Πληρωμών Δήμου Δυτικής Λέσβου'
    }, [])

    const isMobile = useMediaQuery('(max-width:1024px)')
    const { token } = useContext(AuthContext)
    const { getCurrentUserID } = useAuth()
    const userId = getCurrentUserID()
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }

    const { isLoading, data } = useQuery({
        queryKey: ['paymentsHistory', userId, options],
        queryFn: () => getPaymentsOfUser(userId, options),
    })

    if (isLoading) {
        return <Loading />
    }

    const profile = data.profile

    const headings = [
        'Κωδικός Συστήματος',
        'Κωδικός Πληρωμής',
        'Ποσό Πληρωμής',
        'Ημερομηνία Πληρωμής',
        'Κατάσταση',
        'Περισσότερα',
        'Λήψη',
    ]

    const rows = data.payments.map(payment => {
        return {
            0: payment.systemCode ?? '-',
            1: payment.paymentCode ?? '-',
            2: formatAmount(payment.amount) ?? '-',
            3: formatDate(payment.paymentDate) ?? '-',
            4: <StatusText key={1} kind="payment" status={payment.status} />,
            5: <MoreButton key={6} requestID={payment.id} kind={'payments'} />,
            6:
                payment.status === Status.completed ? (
                    <DownloadButton
                        key={7}
                        payment={payment}
                        profile={profile}
                    />
                ) : (
                    <span className="pointer-events-none"> - </span>
                ),
        }
    })

    const sortedPayments = sortByDate(rows, 3)

    return (
        <>
            {isMobile ? (
                <>
                    <h2 className="text-primary text-2xl text-center my-8">
                        Ιστορικό Πληρωμών
                    </h2>

                    {data.payments.length > 0 ? (
                        data.payments.map((payment, index) => {
                            return (
                                <HistoryItem
                                    key={payment.id}
                                    headings={headings}
                                    rows={sortedPayments[index]}
                                />
                            )
                        })
                    ) : (
                        <h2 className="text-base text-primary font-bold text-center">
                            Δεν βρέθηκαν πληρωμές
                        </h2>
                    )}
                </>
            ) : (
                <Modal classes="w-full max-w-7xl flex flex-col">
                    <Header
                        includeLogo={false}
                        withBorder={true}
                        includeButtons={true}
                    />

                    <div className="flex flex-col justify-start items-center grow">
                        <h2 className="text-primary text-3xl font-light mt-12 mb-6">
                            Ιστορικό Πληρωμών
                        </h2>

                        <HistoryTable
                            headings={headings}
                            rows={sortedPayments}
                            noDataText="Δεν βρέθηκαν πληρωμές"
                        />
                    </div>
                </Modal>
            )}
        </>
    )
}

export default PaymentHistory
