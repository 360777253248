import { Link } from 'react-router-dom'
import govPayLogo from '../../../assets/images/govpay-logo.svg'
import govPayWhite from '../../../assets/images/govpay-white.svg'
import useAuth from '../../../hooks/useAuth'

import logo from '../../../assets/images/logo.svg'

const MobileLogo = () => {
    const { isAdmin } = useAuth()
    return (
        <>
            <div
                className={`${
                    isAdmin() ? 'mr-8' : ''
                } flex gap-1 justify-center flex-grow flex-wrap`}
            >
                <Link to="/">
                    <img src={logo} alt="mobile-logo" width="80" height="80" />
                </Link>

                <img
                    src={isAdmin() ? govPayWhite : govPayLogo}
                    alt="mobile-govpay-logo"
                    width="80"
                    height="80"
                />
            </div>
        </>
    )
}

export default MobileLogo
