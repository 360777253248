import Modal from '../../components/Modal/Modal'
import Loading from '../../components/Loading/Loading'
import PopupMessage from '../../components/PopupMessage/PopupMessage'
import editIcon from '../../assets/images/edit.svg'
import eye from '../../assets/images/eye.svg'
import eyeSlash from '../../assets/images/eye-slash.svg'

import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { getRoleText } from '../../helpers/roles'
import { validateEmail, validatePassword } from '../../helpers/validators'
import { getProfile, updateProfile } from '../../api/users'
import useMediaQuery from '../../hooks/useMediaQuery'

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

const AdminProfile = () => {
    useEffect(() => {
        document.title = 'Προφίλ - Πλατφόρμα Ηλ. Πληρωμών Δήμου Αντιπάρου'
    }, [])

    const isMobile = useMediaQuery('(max-width:380px)')
    const [editEmail, setEditEmail] = useState(false)
    const [editPassword, setEditPassword] = useState(null)

    const [passwordVisible, setPasswordVisible] = useState(false)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [passwordValidation, setPasswordValidation] = useState(null)

    const [popup, setPopup] = useState({ message: '', type: '', shown: false })
    const { token } = useContext(AuthContext)
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }
    const queryClient = useQueryClient()

    const toggleEmail = () => {
        setEditEmail(!editEmail)
        setEditPassword(false)
    }

    const togglePassword = () => {
        setEditEmail(false)
        setEditPassword(!editPassword)
    }

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible)
    }

    const { isLoading, data } = useQuery({
        queryKey: ['profile', options],
        queryFn: () => getProfile(options),
    })

    const mutation = useMutation({
        mutationFn: updatedProfile =>
            updateProfile({
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedProfile),
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['profile'],
            })

            setPopup(values => ({
                ...values,
                shown: true,
                message: <p> Επιτυχής αλλαγή email </p>,
                type: 'success',
            }))
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Κάτι πήγε στραβά! </p>
                        <p>Παρακαλώ προσπαθήστε ξανά.</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    if (isLoading) {
        return <Loading />
    }

    const handleEmailUpdate = async () => {
        if (!validateEmail(email)) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Λάθος μορφή email! </p>
                        <p>Παρακαλώ προσπαθήστε ξανά.</p>
                    </>
                ),
                type: 'error',
            }))

            return
        }

        setEditEmail(false)

        mutation.mutate({
            email: email,
        })
        // Parametres need to be checked
    }

    const handlePasswordUpdate = async () => {
        if (!validatePassword(password, passwordValidation)) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Οι κωδικοί δεν ταιριάζουν. </p>
                        <p>Παρακαλώ προσπαθήστε ξανά.</p>
                    </>
                ),
                type: 'error',
            }))

            return
        }

        setEditPassword(false)

        mutation.mutate({
            password: password,
        })
        // Parametres need to be checked
    }

    const handleSubmit = () => {
        if (editEmail) {
            handleEmailUpdate()
        } else {
            handlePasswordUpdate()
        }
    }

    return (
        <>
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                />
            )}

            <div className="flex flex-col justify-center items-center w-full md:w-auto max-w-xl md:max-w-none md:my-14 md:mx-8">
                <Modal classes="flex flex-col gap-4 w-full max-w-4xl my-5">
                    <div className="border-b w-full border-black">
                        <h2 className="text-2xl text-center my-4">
                            Στοιχεία Διαχειριστή
                        </h2>
                    </div>

                    <div className="w-full flex flex-col items-center gap-3 my-8">
                        <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray">
                            <div className="w-1/2 h-max flex-auto px-8 my-1">
                                <p className="font-medium">Ρόλος</p>
                            </div>
                            <div className="w-1/2 h-max flex-auto">
                                <p className="font-medium text-secondary">
                                    {getRoleText(data.role)}
                                </p>
                            </div>
                        </div>
                        <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray break-all">
                            <div className="w-1/2 h-max flex-auto px-8 my-1">
                                <p className="font-medium">Email</p>
                            </div>
                            <div
                                className={`w-1/2 h-max flex-auto flex ${
                                    isMobile ? 'flex-col' : null
                                } justify-between mr-4`}
                            >
                                {editEmail ? (
                                    <input
                                        className="border-gray-300 rounded-md border-2 bg-gray-100 w-full mr-10 p-2"
                                        type="email"
                                        name="email"
                                        defaultValue={data.email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                ) : (
                                    <>
                                        <p
                                            className={`font-medium text-secondary ${
                                                isMobile ? 'text-center' : null
                                            }`}
                                        >
                                            {data.email ?? '-'}
                                        </p>

                                        <button
                                            className={
                                                isMobile
                                                    ? 'self-center pt-2'
                                                    : null
                                            }
                                            onClick={toggleEmail}
                                        >
                                            <img
                                                src={editIcon}
                                                alt="edit-button"
                                            />
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray break-all">
                            <div className="w-1/2 h-max flex-auto px-8 my-1">
                                <p className="font-medium">Κωδικός Πρόσβασης</p>
                            </div>
                            <div
                                className={`w-1/2 h-max flex-auto flex ${
                                    isMobile ? 'flex-col' : null
                                } justify-between mr-4`}
                            >
                                {editPassword ? (
                                    <>
                                        <input
                                            className="border-gray-300 rounded-md border-2 bg-gray-100 w-2/3 mr-10 p-2 ml-1.5"
                                            type={
                                                passwordVisible
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            name="password"
                                            defaultValue={data.password}
                                            onChange={e =>
                                                setPassword(e.target.value)
                                            }
                                        />

                                        <button
                                            className={
                                                isMobile
                                                    ? 'self-center pt-2'
                                                    : null
                                            }
                                            onClick={togglePasswordVisibility}
                                        >
                                            <img
                                                src={
                                                    passwordVisible
                                                        ? eye
                                                        : eyeSlash
                                                }
                                                alt="show-pass"
                                            />
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <p
                                            className={`font-medium text-secondary ${
                                                isMobile ? 'text-center' : null
                                            }`}
                                        >
                                            {data.password ?? '-'}
                                        </p>

                                        <button
                                            className={
                                                isMobile
                                                    ? 'self-center pt-2'
                                                    : null
                                            }
                                            onClick={togglePassword}
                                        >
                                            <img
                                                src={editIcon}
                                                alt="edit-button"
                                            />
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                        {editPassword ? (
                            <div className="profile-item py-1 flex w-full max-w-2xl border-b border-gray">
                                <div className="w-1/2 h-max flex-auto px-8 my-1">
                                    <p className="font-medium">
                                        Επιβεβαίωση κωδικού
                                    </p>
                                </div>
                                <div className="w-1/2 h-max flex-auto">
                                    <p className="font-medium text-secondary">
                                        <>
                                            <input
                                                className="border-gray-300 rounded-md border-2 bg-gray-100 w-2/3 mr-10 p-2"
                                                type={
                                                    passwordVisible
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                name="passwordVal"
                                                defaultValue={data.password}
                                                onChange={e =>
                                                    setPasswordValidation(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </>
                                    </p>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    {(editEmail || editPassword) && (
                        <div className="flex flex-col md:flex-row justify-end m-10">
                            <button
                                className="self-center m-3 w-48 md:order-2 bg-primary text-white border border-primary hover:bg-white hover:text-primary rounded-lg py-3.5 px-10 transition-all"
                                onClick={handleSubmit}
                            >
                                Αποθήκευση
                            </button>
                            <button
                                className="md:mr-14 m-3 w-48 self-center bg-white text-primary border border-primary hover:bg-primary hover:text-white rounded-lg py-3.5 px-10 transition-all"
                                onClick={() => {
                                    setEmail(data.email)
                                    setPassword(data.password)
                                    setEditEmail(false)
                                    setEditPassword(false)
                                }}
                            >
                                Ακύρωση
                            </button>
                        </div>
                    )}
                </Modal>
            </div>
        </>
    )
}

export default AdminProfile
