import Modal from '../../components/Modal/Modal'
import StatusDropDown from '../../components/StatusDropDown/StatusDropDown'
import PopupMessage from '../../components/PopupMessage/PopupMessage'
import Loading from '../../components/Loading/Loading'
import FileBase64 from 'react-file-base64'

import { useContext, useRef, useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { formatDate } from '../../helpers/formatters'
import {
    getMunicipalAwarenessRequest,
    updateMunicipalAwarenessRequest,
} from '../../api/municipal-awareness-requests'

import notFound from '../../assets/images/admin404.png'

const AdminMunicipalAwarenessRequestEdit = () => {
    useEffect(() => {
        document.title =
            'Επεξεργασία Αίτησης Δημοτικής Ενημερότητας - Πλατφόρμα Ηλ. Πληρωμών Δήμου Δυτικής Λέσβου'
    }, [])

    const statusRef = useRef(null)
    const navigate = useNavigate()
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })
    const [values, setValues] = useState({
        canBeIssued: null,
        municipalAwarenessFile: null,
    })

    const { id: municipalAwarenessRequestId } = useParams()
    const { token } = useContext(AuthContext)
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }

    const queryClient = useQueryClient()

    const { isLoading, data } = useQuery({
        queryKey: [
            'adminMunicipalAwarenessRequest',
            municipalAwarenessRequestId,
            options,
        ],
        queryFn: () =>
            getMunicipalAwarenessRequest(municipalAwarenessRequestId, options),
    })

    const handleValuesChange = (name, value) => {
        setValues(prevValues => {
            return {
                ...prevValues,
                [name]: value,
            }
        })
    }

    const handleRequestSave = () => {
        const updateMunicipalAwarenessRequestBody = {
            status: statusRef.current.props.value.value,
            canBeIssued: values.canBeIssued,
            municipalAwarenessFile: values.municipalAwarenessFile,
        }

        mutation.mutate(updateMunicipalAwarenessRequestBody)
    }

    const mutation = useMutation({
        mutationFn: updatedMunicipalAwarenessRequest =>
            updateMunicipalAwarenessRequest(municipalAwarenessRequestId, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedMunicipalAwarenessRequest),
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['adminMunicipalAwarenessRequest'],
            })

            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <p>
                        Οι αλλαγές υποβλήθηκαν με επιτυχία! Μπορείτε να δείτε τη
                        νέα κατάσταση της αίτησης στον πίνακα με τις&nbsp;
                        <Link
                            className="font-bold"
                            to={'/admin/municipal-awareness-request'}
                        >
                            &quot;Αιτήσεις Δημοτικής Ενημερότητας&quot;
                        </Link>
                    </p>
                ),
                type: 'success',
            }))

            setTimeout(() => {
                navigate('/admin/municipal-awareness-request')
            }, 3000)
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Η αίτηση δημοτικής ενημερότητας δεν ενημερώθηκε.</p>
                        <p>Παρακαλώ προσπαθήστε ξανά</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    if (isLoading) {
        return <Loading />
    }

    const municipalAwarenessRequestData = data.data

    if (!municipalAwarenessRequestData) {
        return (
            <>
                <div>
                    <img
                        src={notFound}
                        className="pb-10"
                        alt="Page not found"
                    />
                </div>
            </>
        )
    }

    return (
        municipalAwarenessRequestData && (
            <>
                {popup.shown && (
                    <PopupMessage
                        message={popup.message}
                        type={popup.type}
                        closePopUp={() =>
                            setPopup(values => ({ ...values, shown: false }))
                        }
                        showCloseButton={true}
                    />
                )}

                <div className="flex flex-col py-10 md:py-0">
                    <div className="flex flex-col pb-32">
                        <header className="text-3xl text-center py-12">
                            Επεξεργασία Αιτημάτος Δημοτικής Ενημερότητας
                        </header>

                        <Modal classes="w-[80%] pl-8 pr-12 pb-8 pt-20 self-center md:w-1/2">
                            <div className="flex flex-col self-center justify-around">
                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        ΑΦΜ Οφειλέτη:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {
                                            municipalAwarenessRequestData.user
                                                .profile.taxid
                                        }
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ονοματεπώνυμο Οφειλέτη:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {`${municipalAwarenessRequestData.user.profile.firstName} ${municipalAwarenessRequestData.user.profile.lastName}`}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Τίτλος:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {municipalAwarenessRequestData.title}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ημερομηνία Υποβολής Αίτησης:
                                    </div>

                                    <div className="w-full text-secondary lg:w-2/3">
                                        {formatDate(
                                            municipalAwarenessRequestData.createdAt
                                        )}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ημερομηνία Ενημέρωσης Αίτησης:
                                    </div>

                                    <div className="w-full text-secondary lg:w-2/3">
                                        {formatDate(
                                            municipalAwarenessRequestData.updatedAt
                                        )}
                                    </div>
                                </div>

                                <StatusDropDown
                                    status={
                                        municipalAwarenessRequestData.status
                                    }
                                    kind="request"
                                    reference={statusRef}
                                />

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 mt-10 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Μπορεί να εκδοθεί Δημοτική Ενημερότητα;
                                    </div>

                                    <div className="w-full flex text-secondary lg:w-2/3 gap-8">
                                        <div className="flex gap-2 flex-wrap items-baseline">
                                            <label htmlFor="yes">ΝΑΙ</label>
                                            <input
                                                type="radio"
                                                id="yes"
                                                name="canBeIssued"
                                                value="yes"
                                                onChange={() =>
                                                    handleValuesChange(
                                                        'canBeIssued',
                                                        true
                                                    )
                                                }
                                                checked={
                                                    values.canBeIssued === true
                                                }
                                            />
                                        </div>

                                        <div className="flex gap-2 flex-wrap items-baseline">
                                            <label htmlFor="no">ΟΧΙ</label>
                                            <input
                                                type="radio"
                                                id="no"
                                                name="canBeIssued"
                                                value="no"
                                                onChange={() =>
                                                    handleValuesChange(
                                                        'canBeIssued',
                                                        false
                                                    )
                                                }
                                                checked={
                                                    values.canBeIssued === false
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                {values.canBeIssued ? (
                                    <div className="flex gap-2 flex-wrap items-baseline">
                                        <label htmlFor="municipalAwarenessFile">
                                            Επισύναψη Δημοτικής Ενημερότητας:
                                        </label>

                                        <FileBase64
                                            multiple={false}
                                            onDone={file => {
                                                console.log(file)

                                                if (
                                                    file.type !==
                                                    'application/pdf'
                                                ) {
                                                    setPopup(values => ({
                                                        ...values,
                                                        shown: true,
                                                        message: (
                                                            <>
                                                                <p>
                                                                    Το αρχείο
                                                                    δημοτικής
                                                                    ενημερότητας
                                                                    πρέπει να
                                                                    είναι PDF.
                                                                </p>
                                                            </>
                                                        ),
                                                        type: 'error',
                                                    }))

                                                    return
                                                }

                                                handleValuesChange(
                                                    'municipalAwarenessFile',
                                                    file.base64.split(',')[1] // keep only base64 value
                                                )
                                            }}
                                        />
                                    </div>
                                ) : null}

                                <button
                                    className="mt-16 bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all rounded-lg w-fit py-3.5 px-10 self-center md:self-end"
                                    type="submit"
                                    onClick={handleRequestSave}
                                    disabled={mutation.isLoading}
                                >
                                    Αποθήκευση
                                </button>
                            </div>
                        </Modal>
                    </div>
                </div>
            </>
        )
    )
}

export default AdminMunicipalAwarenessRequestEdit
