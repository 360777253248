import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'

import refreshIcon from '../../assets/images/refresh.svg'
import PopupMessage from '../PopupMessage/PopupMessage'

import useAuth from '../../hooks/useAuth'

const TokenCountdown = () => {
    const { token } = useContext(AuthContext)
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })

    const { parseTokenPayload, logout, refreshToken, isTokenRefreshing } =
        useAuth()

    const tokenPayload = parseTokenPayload(token.access_token)

    // Add milliseconds to the expiration timestamp
    const expirationDate = +`${tokenPayload.exp}000`

    const calculateRemainingTime = () => {
        const diff = expirationDate - new Date().getTime()
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((diff % (1000 * 60)) / 1000)

        return [minutes, seconds]
    }

    const resetTimer = () => {
        setTimer({ minutes: 15, seconds: 0 })
    }

    const formatTime = time => {
        return time < 10 ? `0${time}` : time
    }

    const [minutes, seconds] = calculateRemainingTime()
    const [timer, setTimer] = useState({ minutes: minutes, seconds: seconds })

    useEffect(() => {
        // Don't start the countdown until the token refresh is done
        if (isTokenRefreshing) {
            return
        }

        const countdown = setInterval(() => {
            const [minutes, seconds] = calculateRemainingTime()
            setTimer({ minutes: minutes, seconds: seconds })

            if (minutes === 3 && seconds === 0) {
                setPopup({
                    shown: true,
                    message:
                        'Η συνεδρία πρόκειται να λήξει σύντομα. Ανανεώστε το χρόνο.',
                    type: 'refresh',
                })
            }

            // Logout 1 second earlier to allow a correct POST to the /logout endpoint
            if ((minutes <= 0 && seconds === 1) || minutes < 0) {
                clearInterval(countdown)
                logout()
            }
        }, 1000)

        return () => clearInterval(countdown)
    }, [isTokenRefreshing, token])

    return (
        <>
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                />
            )}

            <div className="flex justify-between items-center text-primary text-lg bg-white rounded border-2 border-accent p-1 w-24">
                <button
                    disabled={isTokenRefreshing}
                    onClick={() => {
                        resetTimer()
                        refreshToken()
                    }}
                    title="Ανανέωση χρόνου"
                >
                    <img
                        width="20"
                        height="20"
                        src={refreshIcon}
                        alt="refresh token"
                        className={isTokenRefreshing ? 'animate-spin' : ''}
                    />
                </button>

                <span>
                    {formatTime(timer.minutes)} : {formatTime(timer.seconds)}
                </span>
            </div>
        </>
    )
}

export default TokenCountdown
