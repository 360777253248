import Modal from '../../components/Modal/Modal'
import StatusDropDown from '../../components/StatusDropDown/StatusDropDown'
import PopupMessage from '../../components/PopupMessage/PopupMessage'
import Loading from '../../components/Loading/Loading'
import { useContext, useRef, useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { formatDate, formatAmount } from '../../helpers/formatters'
import { getKokFine, updateKokFine } from '../../api/kok-fines'
import notFound from '../../assets/images/admin404.png'

const KokFineEdit = () => {
    useEffect(() => {
        document.title =
            'Επεξεργασία Κλήσης Κ.Ο.Κ. - Πλατφόρμα Ηλ. Πληρωμών Δήμου Δυτικής Λέσβου'
    }, [])

    const statusRef = useRef(null)
    const navigate = useNavigate()
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })

    const { id: fineId } = useParams()
    const { token } = useContext(AuthContext)
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }

    const queryClient = useQueryClient()

    const { isLoading, data } = useQuery({
        queryKey: ['adminKokFine', fineId, options],
        queryFn: () => getKokFine(fineId, options),
    })

    const handleRequestSave = () => {
        const updateBalanceRequestBody = {
            status: statusRef.current.props.value.value,
        }
        mutation.mutate(updateBalanceRequestBody)
    }

    const mutation = useMutation({
        mutationFn: updatedKokFine =>
            updateKokFine(fineId, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedKokFine),
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['adminKokFine'],
            })

            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <p>
                        Οι αλλαγές υποβλήθηκαν με επιτυχία! Μπορείτε να δείτε τη
                        νέα κατάσταση της κλησης στον πίνακα με τις&nbsp;
                        <Link className="font-bold" to={'/admin/kok'}>
                            &quot;Κλήσεις Κ.O.K.&quot;
                        </Link>
                    </p>
                ),
                type: 'success',
            }))

            setTimeout(() => {
                navigate('/admin/kok')
            }, 3000)
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Η κλήση δεν ενημερώθηκε.</p>
                        <p>Παρακαλώ προσπαθήστε ξανά</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    if (isLoading) {
        return <Loading />
    }

    const fineData = data.data

    if (!fineData) {
        return (
            <>
                <div>
                    <img
                        src={notFound}
                        className="pb-10"
                        alt="Page not found"
                    />
                </div>
            </>
        )
    }

    return (
        fineData && (
            <>
                {popup.shown && (
                    <PopupMessage
                        message={popup.message}
                        type={popup.type}
                        closePopUp={() =>
                            setPopup(values => ({ ...values, shown: false }))
                        }
                        showCloseButton={true}
                    />
                )}

                <div className="flex flex-col py-10 md:py-0">
                    <div className="flex flex-col pb-32">
                        <header className="text-3xl text-center py-12">
                            Επεξεργασία Κλήσης Κ.Ο.Κ.
                        </header>

                        <Modal classes="w-[80%] pl-8 pr-12 pb-8 pt-20 self-center md:w-1/2">
                            <div className="flex flex-col self-center justify-around">
                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        ΑΦΜ:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {fineData.user.profile.taxid}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ονοματεπώνυμο:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {`${fineData.user.profile.firstName} ${fineData.user.profile.lastName}`}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Αριθμός κλήσης:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {fineData.fineNumber}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ποσό:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {formatAmount(fineData.amount)}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Όχημα:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {fineData.vehicle}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Αριθμός Κυκλοφορίας:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {fineData.vehicleNumber}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ημερομηνία Κλήσης:
                                    </div>

                                    <div className="w-full text-secondary lg:w-2/3">
                                        {formatDate(fineData.dateIssued)}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ημερομηνία υποβολής στο σύστημα:
                                    </div>

                                    <div className="w-full text-secondary lg:w-2/3">
                                        {formatDate(fineData.createdAt)}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ημερομηνία επεξεργασίας στο σύστημα:
                                    </div>

                                    <div className="w-full text-secondary lg:w-2/3">
                                        {formatDate(fineData.updatedAt)}
                                    </div>
                                </div>

                                <StatusDropDown
                                    status={fineData.status}
                                    kind="balance-request"
                                    reference={statusRef}
                                />
                            </div>
                            <div className="flex flex-col justify-end">
                                <button
                                    className="mt-16 bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all rounded-lg w-fit py-3.5 px-10 self-center md:self-end"
                                    type="submit"
                                    onClick={() => handleRequestSave()}
                                    disabled={mutation.isLoading}
                                >
                                    Αποθήκευση
                                </button>
                            </div>
                        </Modal>
                    </div>
                </div>
            </>
        )
    )
}

export default KokFineEdit
