import Modal from '../Modal/Modal'
import PopupMessage from '../PopupMessage/PopupMessage'
import FormBodyDesktop from './FormBodyDesktop'
import FormBodyMobile from './FormBodyMobile'

import { useContext, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { fetchAPI } from '../../helpers/api'

import useAuth from '../../hooks/useAuth'
import useMediaQuery from '../../hooks/useMediaQuery'
import { getProfile } from '../../api/users'

import Loading from '../Loading/Loading'

const PaymentForm = () => {
    const { token } = useContext(AuthContext)
    const { getCurrentUserID, refreshToken } = useAuth()
    const [inputs, setInputs] = useState({})
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })
    const isMobile = useMediaQuery('(max-width:1024px)')
    const [loadingForPay, setLoadingForPay] = useState(false)

    if (loadingForPay) {
        return <Loading />
    }

    const handleChange = e => {
        const name = e.target.name
        const value = e.target.value
        setInputs(values => ({ ...values, [name]: value }))
    }

    const submitHandler = async e => {
        e.preventDefault()
        setLoadingForPay(true)

        setTimeout(() => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Η διαδικασία πληρωμής ακυρώθηκε.</p>
                        <p>Παρακαλώ προσπαθήστε ξανά.</p>
                    </>
                ),
                type: 'errorPayment',
            }))

            setLoadingForPay(false)
        }, 3000)
        return

        // eslint-disable-next-line no-unreachable
        const isFormValid = validateForm()

        if (!isFormValid) {
            return
        }

        const response = await initiateCheckout()

        // TODO Handling bank there, a temp success or false message until get the API

        setPopup(values => ({
            ...values,
            shown: true,
            message: (
                <>
                    <>
                        {response?.success ? (
                            <>
                                <p>Επιτυχής πληρωμή</p>
                            </>
                        ) : (
                            <>
                                <p>Αποτυχία πληρωμής</p>
                                <p>{response?.message}</p>
                            </>
                        )}
                    </>
                </>
            ),
            type: `${response?.success ? 'success' : 'error'}`,
        }))
        return
    }

    const validateForm = () => {
        const validAmount =
            inputs.amount >= 0 && typeof parseInt(inputs.amount) === 'number'

        if (!(inputs.paymentCode && inputs.description && inputs.amount)) {
            setPopup(values => ({
                ...values,
                shown: true,
                message:
                    'Η συμπλήρωση όλων των πεδίων είναι υποχρεωτική για την πληρωμή της οφειλής.',
                type: 'error',
            }))
            return false
        }

        if (!validAmount) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: 'Το ποσό πληρωμής που εισάγατε δεν ειναι αποδεκτό,',
                type: 'error',
            }))
            return false
        }

        return true
    }

    const initiateCheckout = async () => {
        const profileData = await getProfile({
            headers: {
                Authorization: `Bearer ${token.access_token}`,
            },
        })
        const profile = profileData?.profile
        let fullName
        profile
            ? (fullName = `${profile?.firstName} ${profile?.lastName}`)
            : (fullName = '-')
        try {
            // Refresh the tokens before initiating the NBG checkout
            refreshToken()
            const checkoutBody = {
                payment: {
                    description: inputs.description,
                    paymentCode: inputs.paymentCode,
                    amount: parseFloat(inputs.amount),
                    associateTitle: fullName,
                    debts: [
                        {
                            debtId: inputs.paymentCode,
                            totalAmount: parseFloat(inputs.amount),
                        },
                    ],
                },
                userId: getCurrentUserID(),
            }

            const res = await fetchAPI(
                `${process.env.REACT_APP_API_URL}/payments/initiateCheckout`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token.access_token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(checkoutBody),
                }
            )
            return res
        } catch (err) {
            console.error(err)
            return false
        }
    }

    const formBody = (
        <>
            <header className="font-light text-3xl text-center border-b border-primary py-6 self-stretch">
                Πληρωμή Οφειλής
            </header>

            {isMobile ? (
                <FormBodyMobile
                    handleChange={handleChange}
                    submitHandler={submitHandler}
                />
            ) : (
                <FormBodyDesktop
                    handleChange={handleChange}
                    submitHandler={submitHandler}
                />
            )}
        </>
    )

    return (
        <>
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                />
            )}
            <Modal classes="w-1/2 self-center justify-self-center">
                {formBody}
            </Modal>
        </>
    )
}

export default PaymentForm
