import Modal from '../Modal/Modal'
import PropTypes from 'prop-types'
import '../ModalToggle/ModalToggle.css'
import down from '../../assets/images/down.svg'
import { useState, useRef } from 'react'
import useMediaQuery from '../../hooks/useMediaQuery'
import { formatDate } from '../../helpers/formatters'
import useAuth from '../../hooks/useAuth'
import { getPaymentStatus } from '../../helpers/payments'

function ModalToggle({ data, type, onDelete, id, pay, paymentsToCheck }) {
    const { getUserRole } = useAuth()
    const role = getUserRole()
    const [active, setActive] = useState(false)
    const toggleAccordion = () => {
        setActive(!active)
    }
    const AccContent = useRef()
    const isMobile = useMediaQuery('(max-width:1024px)')

    const handleDeleteClick = () => {
        onDelete()
    }

    const handlePay = () => {
        pay()
    }

    let paymentStatus
    if (data?.paymentCode && paymentsToCheck && paymentsToCheck.length > 0) {
        paymentStatus = getPaymentStatus(paymentsToCheck, data?.paymentCode)
    }

    return (
        <>
            <Modal classes="w-full max-w-max p-6 lg:p-0 self-center md:pt-14 mb-14 lg:pt-2 rounded-2xl">
                <header
                    className={`${
                        active
                            ? 'border-b border-primary transition duration-300'
                            : ''
                    } font-light text-2xl text-center self-stretch mb-3 flex flex-wrap gap-5 justify-center py-1 relative`}
                >
                    <p
                        className={
                            isMobile
                                ? 'text-lg px-6 w-full'
                                : 'text-2xl flex gap-2 px-6'
                        }
                    >
                        {type === 'info' ? (
                            <>
                                <span className="font-bold w-fit">Οφειλή:</span>
                                <span className="block max-w-[500px] w-full overflow-auto break-words lg:break-normal">
                                    {data?.description}
                                </span>
                            </>
                        ) : (
                            'Προσθήκη οφειλής'
                        )}
                    </p>
                    <button
                        className={
                            active
                                ? 'activetoggleBtn toggleBtn '
                                : 'toggleBtn  '
                        }
                        onClick={toggleAccordion}
                    >
                        <img
                            src={down}
                            className="accordion-icon self-center invert"
                            alt="chevron-icon"
                        />
                    </button>
                </header>
                <div
                    ref={AccContent}
                    className={`collapse-modal ${active ? 'show' : ''}`}
                    style={
                        active
                            ? { height: AccContent.current.scrollHeight }
                            : { height: '0px' }
                    }
                >
                    <div className="flex flex-col md:pb-5 my-2 lg:my-8">
                        <div className="flex flex-col self-center w-full justify-center p-5 md:p-0">
                            <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14 justify-center">
                                <div className="w-full text-left lg:text-right lg:w-1/3">
                                    Κωδικός Πληρωμής Δήμου:
                                </div>
                                <div className="w-full text-secondary lg:w-[40%] border-b">
                                    {type === 'info' ? (
                                        <p>{data?.paymentCode}</p>
                                    ) : (
                                        <input
                                            type="text"
                                            id={`${id}-paymentCode`}
                                            name={`${id}-paymentCode`}
                                        />
                                    )}
                                </div>
                            </div>
                            {type === 'info' ? (
                                <>
                                    <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14 justify-center">
                                        <div className="w-full text-left lg:text-right lg:w-1/3">
                                            Κωδικός Govpay:
                                        </div>
                                        <div className="w-full text-secondary lg:w-[40%] border-b">
                                            {type === 'info' ? (
                                                <p>{data?.systemCode}</p>
                                            ) : (
                                                <input
                                                    type="text"
                                                    id={`${id}-govpayCode`}
                                                    name="govpayCode"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : null}

                            <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14 justify-center">
                                <div className="w-full text-left lg:text-right lg:w-1/3">
                                    Αιτιολογία:
                                </div>
                                <div className="w-full text-secondary lg:w-[40%] border-b">
                                    {type === 'info' ? (
                                        <p className="max-w-[320px] w-full overflow-auto">
                                            {data?.description}
                                        </p>
                                    ) : (
                                        <input
                                            type="text"
                                            id={`${id}-description`}
                                            name={`${id}-description`}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14 justify-center">
                                <div className="w-full text-left lg:text-right lg:w-1/3">
                                    Ποσό:
                                </div>
                                <div className="w-full text-secondary lg:w-[40%] border-b">
                                    {type === 'info' ? (
                                        <p>{data?.amount}</p>
                                    ) : (
                                        <input
                                            type="number"
                                            id={`${id}-price`}
                                            name={`${id}-price`}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row lg:mb-14 justify-center">
                                <div className="w-full text-left lg:text-right lg:w-1/3">
                                    Ποσό Ολογράφως:
                                </div>
                                <div className="w-full text-secondary lg:w-[40%] border-b">
                                    {type === 'info' ? (
                                        <p>{data.amountString}</p>
                                    ) : (
                                        <input
                                            type="text"
                                            id={`${id}-fullPrice`}
                                            name={`${id}-fullPrice`}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="text-base text-primary mb-5 flex flex-col gap-4 items-center w-full font-bold lg:flex-row  justify-center">
                                <div className="w-full text-left lg:text-right lg:w-1/3">
                                    Καταληκτική Ημερομηνία πληρωμής χωρίς
                                    προσαύξηση:
                                </div>
                                <div className="w-full text-secondary lg:w-[40%] border-b">
                                    {type === 'info' ? (
                                        <p>
                                            {formatDate(
                                                data?.expirationDate,
                                                true
                                            )}
                                        </p>
                                    ) : (
                                        <input
                                            type="date"
                                            id={`${id}-endDate`}
                                            name={`${id}-endDate`}
                                        />
                                    )}
                                </div>
                            </div>
                            {type === 'add' ? (
                                <div className="flex w-full justify-end">
                                    <button
                                        className="rounded-md border border-[#E37D75] text-[#E37D75] py-3 px-10 font-bold mx-8 my-4 hover:border-[#FF4B3C] hover:text-[#FF4B3C] max-w-[230px] transition-all duration-300"
                                        onClick={handleDeleteClick}
                                    >
                                        Διαγραφή οφειλής
                                    </button>
                                </div>
                            ) : (
                                <div className="flex w-full justify-end">
                                    {role === 'ADMIN' ? (
                                        <button
                                            className="rounded-md border border-[#E37D75] text-[#E37D75] py-3 px-10 font-bold mx-8 my-4 hover:border-[#FF4B3C] hover:text-[#FF4B3C] max-w-[230px] transition-all duration-300"
                                            onClick={handleDeleteClick}
                                        >
                                            Διαγραφή οφειλής
                                        </button>
                                    ) : (
                                        (!paymentStatus ||
                                            paymentStatus === 'DECLINED') && (
                                            <button
                                                className="rounded-md bg-primary text-white border border-primary hover:bg-white hover:text-primary py-3 px-10 font-bold mx-8 my-4 max-w-[230px] transition-all duration-300"
                                                onClick={handlePay}
                                            >
                                                Πληρωμή
                                            </button>
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

ModalToggle.propTypes = {
    data: PropTypes.object,
    content: PropTypes.node,
    type: PropTypes.oneOf(['add', 'info']),
    onDelete: PropTypes.func,
    id: PropTypes.string,
    pay: PropTypes.func,
    paymentsToCheck: PropTypes.array,
}

export default ModalToggle
