import _fetch from '.'

export async function getConfirmedDebts(options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/debts/alphaware?debtType=CONFIRMED`
    return _fetch(url, options)
}

export async function getInstallmentDebts(options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/debts/alphaware?debtType=INSTALLMENTS`
    return _fetch(url, options)
}

export async function deleteGovpayDebt(debtId, options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/debts/${debtId}`
    return _fetch(url, options)
}
