import { Outlet, useLocation } from 'react-router-dom'
import { useEffect } from 'react'

import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import Sidebar from '../components/Sidebar/Sidebar'

import useAuth from '../hooks/useAuth'
import useMediaQuery from '../hooks/useMediaQuery'

const Index = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const { isLoggedIn } = useAuth()
    const isMobile = useMediaQuery('(max-width:1024px)')
    const location = useLocation()
    return (
        <>
            {isMobile ? <Header /> : null}

            <main
                className={
                    !isMobile
                        ? `flex ${
                              /**
                               * Check if it's the HomeLoggedOut component because it requires
                               * different classes to display propely
                               */
                              location.pathname === '/' && !isLoggedIn()
                                  ? 'flex-col'
                                  : 'my-14 mx-4 md:mx-20 justify-center'
                          }`
                        : `flex flex-col ${
                              location.pathname === '/' && !isLoggedIn()
                                  ? ''
                                  : 'my-2 mb-14 mx-4 md:mx-20 justify-center'
                          }`
                }
            >
                {isLoggedIn() && !isMobile ? <Sidebar /> : null}

                <Outlet />
            </main>
            <Footer />
        </>
    )
}

export default Index
