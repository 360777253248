import { useState, useEffect } from 'react'
import useAuth from '../../../hooks/useAuth'
import NavItem from '../../NavItem/NavItem'
import Footer from '../../Footer/Footer'

const BurgerMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false)
    const { logout, isAdmin } = useAuth()

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    useEffect(() => {
        const menuHeader = document.getElementById('mobile-header')
        const profileButton = document.getElementById('profileBtn')
        const closeMenu = () => {
            setMenuOpen(false)
        }
        if (!menuOpen) {
            if (isAdmin()) {
                menuHeader.classList.remove('fixed')
                menuHeader.classList.add('rounded-b-[30px]')
            } else {
                menuHeader.classList.remove('fixed', 'bg-white')
            }
        } else {
            if (isAdmin()) {
                menuHeader.classList.add('fixed')
                menuHeader.classList.remove('rounded-b-[30px]')
            } else {
                menuHeader.classList.add('fixed', 'bg-white')
                menuHeader.classList.remove('bg-[#f0f3f4]')
                if (profileButton) {
                    profileButton.addEventListener('click', closeMenu)
                }
            }
        }
        return () => {
            if (profileButton) {
                profileButton.removeEventListener('click', closeMenu)
            }
        }
    }, [menuOpen])

    const adminClasses = 'font-bold text-2xl text-white'
    const userClasses = 'px-4 py-2 rounded-lg font-bold text-2xl'

    return (
        <>
            <button onClick={toggleMenu}>
                <span className="font-bold text-5xl" aria-hidden="true">
                    {menuOpen ? (
                        isAdmin() ? (
                            <div className="-space-y-1">
                                <div className="w-8 h-1 bg-white rotate-45"></div>
                                <div className="w-8 h-1 bg-white -rotate-45"></div>
                            </div>
                        ) : (
                            <div className="-space-y-1">
                                <div className="w-8 h-1 bg-accent rotate-45"></div>
                                <div className="w-8 h-1 bg-accent -rotate-45"></div>
                            </div>
                        )
                    ) : isAdmin() ? (
                        <div className="space-y-2">
                            <div className="w-8 h-1 bg-white"></div>
                            <div className="w-8 h-1 bg-white"></div>
                        </div>
                    ) : (
                        <div className="space-y-2">
                            <div className="w-8 h-1 bg-accent"></div>
                            <div className="w-8 h-1 bg-accent"></div>
                        </div>
                    )}
                </span>
            </button>

            {menuOpen ? (
                <>
                    <div
                        className={`fixed w-full z-10 left-0 top-[122px] ${
                            isAdmin() ? 'bg-primary' : 'bg-white'
                        } h-full`}
                    >
                        <div className="h-full flex flex-col items-center">
                            <ul className="flex gap-8 flex-col items-center justify-center pt-10">
                                {isAdmin() ? (
                                    <>
                                        <NavItem
                                            classes={adminClasses}
                                            path="/admin/payment"
                                            text="Πληρωμές Οφειλών"
                                            closeFunction={toggleMenu}
                                        />

                                        <NavItem
                                            classes={adminClasses}
                                            path="/admin/balance-request"
                                            text="Αιτήσεις Υπολοίπου"
                                            closeFunction={toggleMenu}
                                        />

                                        <NavItem
                                            classes={adminClasses}
                                            path="/admin/kok"
                                            text="Κλήσεις Κ.Ο.Κ."
                                            closeFunction={toggleMenu}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <NavItem
                                            classes={userClasses}
                                            hasSubmenu={true}
                                            text="Οι οφειλές μου"
                                            subMenuItems={[
                                                {
                                                    text: 'Πληρωμή Οφειλής',
                                                    path: '/payment',
                                                },
                                                {
                                                    text: 'Βεβαιωμένες Οφειλές',
                                                    path: '/debts/confirmed',
                                                },
                                                {
                                                    text: 'Οφειλές σε Ρύθμιση',
                                                    path: '/debts/installments',
                                                },
                                                {
                                                    text: 'Οφειλές από Αίτηση Υπολοίπου',
                                                    path: '/balance-request-debts',
                                                },
                                                {
                                                    text: 'Ιστορικό Πληρωμών',
                                                    path: '/payments',
                                                },
                                            ]}
                                            closeFunction={toggleMenu}
                                        />

                                        <NavItem
                                            classes={userClasses}
                                            hasSubmenu={true}
                                            text="Κλήσεις Κ.Ο.Κ"
                                            subMenuItems={[
                                                {
                                                    text: 'Οι κλήσεις μου',
                                                    path: '/fines',
                                                },
                                                {
                                                    text: 'Ιστορικό Κλήσεων',
                                                    path: '/fines-history',
                                                },
                                            ]}
                                            closeFunction={toggleMenu}
                                        />

                                        <NavItem
                                            classes={userClasses}
                                            path="/requests"
                                            text="Αίτημα - Βεβαίωση"
                                            closeFunction={toggleMenu}
                                        />
                                    </>
                                )}

                                <button
                                    className={`font-bold text-2xl ${
                                        isAdmin() ? 'text-white' : ''
                                    }`}
                                    onClick={logout}
                                >
                                    Αποσύνδεση
                                </button>
                            </ul>
                        </div>
                        <Footer inMenu={true} />
                    </div>
                </>
            ) : null}
        </>
    )
}

export default BurgerMenu
