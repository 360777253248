import PropTypes from 'prop-types'
import error from '../../assets/images/error.svg'
import errorPayment from '../../assets/images/error-payment.svg'
import success from '../../assets/images/success.svg'
import successPayment from '../../assets/images/success-payment.svg'
import refresh from '../../assets/images/refresh-popup-icon.svg'
import xbutton from '../../assets/images/xbutton.svg'

const PopupMessage = ({
    message,
    closePopUp,
    type,
    showCloseButton = true,
}) => {
    let image
    switch (type) {
        case 'error':
            image = error
            break
        case 'errorPayment':
            image = errorPayment
            break
        case 'success':
            image = success
            break
        case 'successPayment':
            image = successPayment
            break
        case 'refresh':
            image = refresh
            break
        default:
            break
    }
    return (
        <div
            className="fixed top-0 left-0 w-full h-full bg-[#7777774D] flex justify-center items-center z-10 p-8"
            onClick={closePopUp}
        >
            <div className="w-full max-w-2xl bg-white py-10 px-12 rounded-3xl mb-24 z-10 relative md:m-10">
                {showCloseButton ? (
                    <button
                        className="absolute right-10 top-10"
                        onClick={closePopUp}
                    >
                        <img src={xbutton} alt="close" />
                    </button>
                ) : null}

                <img className="mx-auto my-8" src={image} alt="popupicon" />
                <hr />
                <div className="text-primary text-xl text-center font-medium my-10">
                    {message}
                </div>
            </div>
        </div>
    )
}

PopupMessage.propTypes = {
    message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    closePopUp: PropTypes.func,
    type: PropTypes.oneOf([
        'error',
        'errorPayment',
        'success',
        'successPayment',
        'refresh',
    ]),
    showCloseButton: PropTypes.bool,
}

export default PopupMessage
