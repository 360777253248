import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import HomeLoggedOut from '../components/HomeLoggedOut/HomeLoggedOut'
import HomeLoggedIn from '../components/HomeLoggedIn/HomeLoggedIn'

import useAuth from '../hooks/useAuth'

const Home = () => {
    const { isLoggedIn, isAdmin } = useAuth()

    useEffect(() => {
        document.title = 'Πλατφόρμα Ηλ. Πληρωμών - Δήμος Δυτικής Λέσβου'
    }, [])

    return (
        <>
            {isLoggedIn() ? (
                isAdmin() ? (
                    <Navigate to="/admin" replace={true} />
                ) : (
                    <HomeLoggedIn />
                )
            ) : (
                <HomeLoggedOut />
            )}
        </>
    )
}

export default Home
