import { useRef } from 'react'
import PropTypes from 'prop-types'
import '../AccordionToggle/AccordionToggle.css'
import down from '../../assets/images/down.svg'

const AccordionToggleItem = props => {
    const AccContent = useRef()
    const { handleToggle, active, faq } = props
    const { accordionTitle, id, accordionContent } = faq

    return (
        <div className="accordion-card my-12 rounded-md overflow-hidden">
            <div className="accordion-header">
                <div
                    className={`accordion-toggle flex justify-center bg-accent p-3 relative ${
                        active === id ? 'active text-white bg-accent' : ''
                    }`}
                    onClick={() => handleToggle(id)}
                >
                    <h5 className="accordion-title mb-0 relative flex-1 text-center text-2xl text-white">
                        {accordionTitle}
                    </h5>
                    <img
                        src={down}
                        className="accordion-icon self-center"
                        alt="chevron-icon"
                    />
                </div>
            </div>
            <div
                ref={AccContent}
                className={`collapse ${active === id ? 'show' : ''}`}
                style={
                    active === id
                        ? { height: AccContent.current.scrollHeight }
                        : { height: '0px' }
                }
            >
                <div className="accordion-body text-md">
                    <div className="mb-0 flex flex-col gap-5">
                        {accordionContent}
                    </div>
                </div>
            </div>
        </div>
    )
}

AccordionToggleItem.propTypes = {
    handleToggle: PropTypes.func,
    active: PropTypes.number,
    faq: PropTypes.object,
}

export default AccordionToggleItem
