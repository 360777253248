import './HomeLoggedOut.css'

import balance from '../../assets/images/upoloipo.svg'
import payment from '../../assets/images/ofeili.svg'
import kok from '../../assets/images/kok.svg'

import { Link } from 'react-router-dom'

const MobileTopPart = () => {
    return (
        <>
            <div className="w-full flex homeLoggedOut">
                <div className="w-1/3 flex-auto px-8 my-10 boxes-center">
                    <div className="flex flex-col h-full justify-center">
                        <p className="text-center pb-16 px-10 font-bold">
                            Η ηλεκτρονική υπηρεσία GovPay απευθύνεται σε πολίτες
                            και επιχειρήσεις του Δήμου Δυτικής Λέσβου και έχει
                            ως στόχο την απλοποίηση των διαδικασιών σε μια σειρά
                            θεμάτων πληρωμών που σχετίζονται με το Δήμο, όπως
                            είναι το παράβολο πολιτικού γάμου, τα τέλη άρδευσης
                            κ.ά.
                        </p>
                        <div className="w-full flex flex-col gap-16 justify-center px-5">
                            <div className="box p-4 bg-white text-center self-center w-full max-w-[400px]">
                                <Link
                                    to="/payment"
                                    className="flex flex-col md:flex-row items-center justify-center gap-3"
                                >
                                    <img
                                        className="max-h-16"
                                        src={payment}
                                        alt="ofeili"
                                    />
                                    <h2 className="text-xl font-bold text-accent">
                                        Πληρωμή Οφειλής
                                    </h2>
                                </Link>
                            </div>
                            <div className="box p-4 bg-white text-center self-center w-full max-w-[400px]">
                                <Link
                                    to="/balance-requests"
                                    className="flex flex-col md:flex-row items-center gap-3"
                                >
                                    <img
                                        className="max-h-16"
                                        src={balance}
                                        alt="aitisi"
                                    />
                                    <h2 className="text-xl font-bold text-accent">
                                        Αίτηση Υπολοίπου
                                    </h2>
                                </Link>
                            </div>
                            <div className="flex-col flex gap-4">
                                <div className="box p-4 bg-white text-center self-center w-full max-w-[400px]">
                                    <Link
                                        to="/fines"
                                        className="flex flex-col md:flex-row items-center justify-center gap-3"
                                    >
                                        <img
                                            className="max-h-16"
                                            src={kok}
                                            alt="ofeili"
                                        />
                                        <h2 className="text-xl font-bold text-accent">
                                            Κλήσεις Κ.Ο.Κ.
                                        </h2>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileTopPart
