/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Modal from '../components/Modal/Modal'
import Select from 'react-select'

import userIcon from '../assets/images/user-icon.svg'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Announcement from '../components/Announcement/Announcement'

import useAuth from '../hooks/useAuth'
import FormField from '../components/FormField/FormField'
import PopupMessage from '../components/PopupMessage/PopupMessage'
import Loading from '../components/Loading/Loading'
import { fetchAPI } from '../helpers/api'
import {
    validateAfm,
    validateEmail,
    validateName,
    validatePassword as _validatePassword,
    validateYear,
} from '../helpers/validators'
import Roles from '../enums/Roles'

const Register = () => {
    const { isLoggedIn } = useAuth()
    const [inputs, setInputs] = useState({})
    const [loading, setLoading] = useState(false)
    const [category, setCategory] = useState(Roles.citizen)
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })

    const createUserURL = `${process.env.REACT_APP_API_URL}/auth/jwt/register`
    const patchUserURL = `${process.env.REACT_APP_API_URL}/users/me`

    const [errorMsg, setErrorMsg] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Εγγραφή - Πλατφόρμα Ηλ. Πληρωμών Δήμου Δυτικής Λέσβου'
        if (isLoggedIn()) {
            navigate('/')
        }
    }, [])

    if (loading) {
        return <Loading />
    }

    // FIRST CALL TO API
    const createUser = async () => {
        const requestBody = JSON.stringify({
            email: inputs.email,
            password: inputs.password,
            passwordVerify: inputs.passwordConfirmation,
            role: category,
        })

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: requestBody,
        }

        const res = await fetchAPI(createUserURL, options)

        return res
    }

    const completeUser = async userToken => {
        const requestBody = JSON.stringify({
            email: inputs.email,
            password: inputs.password,
            passwordVerify: inputs.passwordConfirmation,
            role: category,
            taxid: inputs.afm,
            firstName: inputs.fname ? inputs.fname : '',
            lastName: inputs.lname,
            fatherName: inputs.fatherName ? inputs.fname : '',
            motherName: inputs.motherName ? inputs.fname : '',
            birthYear: inputs.birthYear ? inputs.birthYear : '',
        })

        const options = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${userToken}`,
                'Content-Type': 'application/json',
            },
            body: requestBody,
        }
        const res = await fetchAPI(patchUserURL, options)
        return res
    }

    const registerNewUser = async () => {
        setLoading(true)
        const creationResponse = await createUser() // FIRST CALL TO BACKEND
        if (creationResponse.statusCode === 403) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: 'To email που εισάγετε χρησιμοποιείται ήδη',
                type: 'error',
            }))

            setLoading(false)
            return false
        }
        const jwtToken = creationResponse

        const completionResponse = await completeUser(jwtToken.access_token) // SECOND CALL TO BACKEND
        if (
            completionResponse.statusCode < 500 &&
            completionResponse.statusCode >= 400
        ) {
            setPopup(values => ({
                ...values,
                shown: true,
                message:
                    'Κάτι πήγε στραβά. Παρακαλώ ελέγξτε τα στοιχεία σας και το Α.Φ.Μ. σας και ξαναδοκιμάστε',
                type: 'error',
            }))
            return false
        }
        setLoading(false)

        /*
         * sessionStorage.setItem('govpay-jwt', JSON.stringify(jwtToken))
         * setToken(jwtToken)
         */

        return true
    }

    const validatePasswords = () => {
        const validPassword = _validatePassword(
            inputs.password,
            inputs.passwordConfirmation
        )
        if (!validPassword) {
            setErrorMsg(true)
        } else {
            setErrorMsg(false)
        }
    }

    const validateForm = () => {
        if (
            !inputs.email ||
            !inputs.password ||
            !inputs.lname ||
            !inputs.afm ||
            (category === Roles.citizen &&
                (!inputs.fname ||
                    !inputs.motherName ||
                    !inputs.fatherName ||
                    !inputs.birthYear))
        ) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: 'Παρακαλώ συμπληρώστε όλα τα πεδία της φόρμας.',
                type: 'error',
            }))
            return false
        }

        const validEmail = validateEmail(inputs.email)
        if (!validEmail) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: 'Παρακαλώ εισάγετε μία σωστή διεύθυνση email.',
                type: 'error',
            }))
            return false
        }

        const validYear = validateYear(inputs.birthYear)
        if (!validYear) {
            setPopup(values => ({
                ...values,
                shown: true,
                message:
                    'Παρακαλώ εισάγετε μία έγκυρη ημερομηνία. Για να εγγραφείτε στη πλατφόρμα πρέπει να είστε απο 18 ετών και πάνω',
                type: 'error',
            }))
            return false
        }

        const validPassword = _validatePassword(
            inputs.password,
            inputs.passwordConfirmation
        )
        if (!validPassword) {
            setErrorMsg(true)
            return false
        } else {
            setErrorMsg(false)
        }

        const validNames = !(
            validateName(inputs.lname) ||
            (category === Roles.citizen &&
                (validateName(inputs.fname) ||
                    validateName(inputs.fatherName) ||
                    validateName(inputs.motherName)))
        )

        if (validNames) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: 'Παρακαλώ εισάγετε τα ονόματα σε σωστή μορφή',
                type: 'error',
            }))
            return false
        }

        const validAfm = validateAfm(inputs.afm)
        if (!validAfm) {
            setPopup(values => ({
                ...values,
                shown: true,
                message:
                    'Παρακαλώ εισάγετε έναν έγκυρο Αριθμό Φορολογικού Μητρώου.',
                type: 'error',
            }))
            return false
        }

        return true
    }

    const handleChange = event => {
        const name = event.target.name
        const value = event.target.value

        if (name === 'password' || name === 'passwordConfirmation') {
            validatePasswords()
        }
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setPopup(values => ({
            ...values,
            shown: true,
            message: 'Κάτι πήγε στραβά.',
            type: 'error',
        }))
        return
        const formValid = validateForm()
        if (!formValid) {
            return
        }

        try {
            if (await registerNewUser()) {
                setPopup(values => ({
                    ...values,
                    shown: true,
                    message:
                        'Επιτυχής εγγραφή χρήστη, ελέγξετε τα email σας για να επιβεβαιώσετε τον λογαριασμό σας και έπειτα συνδεθείτε στην πλατφόρμα!',
                    type: 'success',
                }))

                setTimeout(() => {
                    navigate('/')
                }, 4500)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                />
            )}
            <main className="flex justify-center">
                <Modal classes="max-w-5xl w-full  m-12">
                    <Header includeLogo={true} withBorder={true} />
                    <div className="px-0 lg:py-16 :gpx-28 text-center">
                        <Announcement
                            date="22/4/2024"
                            title="Aνακοίνωση - Απάντηση Αιτήματος Απριλίου"
                            content={
                                <>
                                    <p className="text-gray-700 mb-4">
                                        Μπορείτε να απευθύνεστε στις αρμόδιες
                                        υπηρεσίες (
                                        <a
                                            href="https://www.mwlesvos.gr/tilefonikos-katalogos/"
                                            className="text-blue-500 hover:underline text-[#61703f] font-bold"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Σχετικά στοιχεία
                                        </a>
                                        ) προσκομίζοντας τα σχετικά
                                        δικαιολογητικά ταυτοποίησης κατά την
                                        επικοινωνία σας.
                                    </p>
                                </>
                            }
                        />
                        <h1 className="font-bold text-primary text-3xl">
                            Εγγραφή
                        </h1>
                        <Modal classes="px-0 max-w-10xl m-0 lg:m-24 lg:px-8">
                            <div className="flex-col flex m-20">
                                <div className="bg-accent rounded-full w-fit p-6 my-10 self-center">
                                    <img
                                        width="30"
                                        height="30"
                                        src={userIcon}
                                        alt="user"
                                    />
                                </div>

                                <form
                                    className="flex flex-col"
                                    onSubmit={handleSubmit}
                                >
                                    <label className="text-base text-left text-primary mb-12 mt-5">
                                        <div className="text-lg font-bold pb-3">
                                            Κατηγορία Λογαριασμού
                                        </div>
                                        <Select
                                            isSearchable={false}
                                            defaultValue={{
                                                value: Roles.citizen,
                                                label: 'Δημότης',
                                            }}
                                            value={inputs.categoy}
                                            onChange={e => {
                                                setCategory(e.value)
                                            }}
                                            name="category"
                                            className="text-left"
                                            options={[
                                                {
                                                    label: 'Δημότης',
                                                    value: Roles.citizen,
                                                },
                                                {
                                                    label: 'Εταιρεία',
                                                    value: Roles.company,
                                                },
                                            ]}
                                        />
                                    </label>
                                    <FormField
                                        type="email"
                                        label="Email"
                                        name="email"
                                        labelOnSide={false}
                                        onChange={handleChange}
                                        autoComplete="new-email"
                                        value={inputs.email}
                                    />
                                    <FormField
                                        type="password"
                                        label="Κωδικός"
                                        name="password"
                                        labelOnSide={false}
                                        onChange={handleChange}
                                        onBlur={validatePasswords}
                                        autoComplete="new-password"
                                        value={inputs.password}
                                    />
                                    <FormField
                                        type="password"
                                        label="Επιβεβαίωση Κωδικού Πρόσβασης"
                                        name="passwordConfirmation"
                                        labelOnSide={false}
                                        onChange={handleChange}
                                        onBlur={validatePasswords}
                                        value={inputs.passwordConfirmation}
                                    />
                                    {errorMsg ? (
                                        <div className=" self-start text-left underline text-red-700 text-sm pb-7">
                                            Οι κωδικοί πρέπει να είναι ίδιοι
                                            μεταξύ τους και να μην περιέχουν
                                            κενά
                                        </div>
                                    ) : null}

                                    {category === Roles.citizen ? (
                                        <>
                                            <FormField
                                                type="text"
                                                label="Όνομα"
                                                name="fname"
                                                labelOnSide={false}
                                                onChange={handleChange}
                                                value={inputs.fname}
                                            />
                                            <FormField
                                                type="text"
                                                label="Επώνυμο"
                                                name="lname"
                                                labelOnSide={false}
                                                onChange={handleChange}
                                                value={inputs.lname}
                                            />
                                            <FormField
                                                type="text"
                                                label="Όνομα Πατέρα"
                                                name="fatherName"
                                                labelOnSide={false}
                                                onChange={handleChange}
                                                value={inputs.fatherName}
                                            />
                                            <FormField
                                                type="text"
                                                label="Όνομα Μητέρας"
                                                name="motherName"
                                                labelOnSide={false}
                                                onChange={handleChange}
                                                value={inputs.motherName}
                                            />
                                            <FormField
                                                type="number"
                                                label="Έτος Γέννησης"
                                                name="birthYear"
                                                labelOnSide={false}
                                                onChange={handleChange}
                                                value={inputs.birthYear}
                                            />
                                        </>
                                    ) : (
                                        <FormField
                                            type="text"
                                            label="Επωνυμία"
                                            name="lname"
                                            labelOnSide={false}
                                            onChange={handleChange}
                                            value={inputs.lname}
                                        />
                                    )}

                                    <FormField
                                        type="number"
                                        label="Α.Φ.Μ."
                                        name="afm"
                                        labelOnSide={false}
                                        onChange={handleChange}
                                        value={inputs.afm}
                                    />
                                    <div className=" self-start font-extrabold text-left text-sm">
                                        Παρακαλώ εισάγατε τα σωστά σας δεδομένα
                                        ώστε η σελίδα πληρωμών να λειτουργεί
                                        σωστά
                                    </div>

                                    <div className="flex flex-row justify-between py-12">
                                        {/* <Link
                                            to="/login"
                                            className="self-auto bg-primary text-white border border-primary hover:bg-white hover:text-primary rounded-lg py-3.5 px-10 transition-all"
                                        >
                                            Σύνδεση
                                        </Link> */}
                                        <button
                                            type="submit"
                                            className="self-auto bg-darkBlue text-white border border-darkBlue hover:bg-darkBlue hover:text-white rounded-lg py-3.5 px-10 transition-all"
                                        >
                                            Εγγραφή
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Modal>
                    </div>
                </Modal>
            </main>

            <Footer />
        </>
    )
}

export default Register
