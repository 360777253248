/* eslint-disable react/prop-types */
const Announcement = ({ date, title, content }) => {
    return (
        <div className="max-w-lg mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-16">
            <h1 className="text-xl font-bold text-center">{date}</h1>
            <h2 className="text-lg font-bold mb-4 text-center">{title}</h2>
            {content}
        </div>
    )
}

export default Announcement
