import { Navigate, useLocation } from 'react-router-dom'

import Roles from '../../enums/Roles'
import useAuth from '../../hooks/useAuth'
import PropTypes from 'prop-types'

const ProtectedRoute = ({ children, allowedRoles }) => {
    const { isLoggedIn, getUserRole, getEmailVerified } = useAuth()
    const location = useLocation()

    if (!isLoggedIn()) {
        return <Navigate to="/login" replace={true} />
    }

    const emailVerifed = getEmailVerified()
    if (!emailVerifed && location.pathname !== '/profile') {
        return <Navigate to="/profile" replace={true} />
    }

    const userRole = getUserRole()

    if (!allowedRoles.includes(userRole)) {
        return userRole === Roles.admin ? (
            <Navigate to="/admin" replace={true} />
        ) : (
            <Navigate to="/" replace={true} />
        )
    }

    return children
}

ProtectedRoute.propTypes = {
    children: PropTypes.object,
    allowedRoles: PropTypes.arrayOf(PropTypes.string),
}

export default ProtectedRoute
