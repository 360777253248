import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import edit from '../../assets/images/edit.svg'

const EditButton = ({ id, kind }) => {
    return (
        <Link to={`/admin/${kind}/${id}`} id={id}>
            <img
                className="mx-auto"
                width="22"
                height="22"
                src={edit}
                alt="edit-button"
            />
        </Link>
    )
}

EditButton.propTypes = {
    id: PropTypes.number.isRequired,
    kind: PropTypes.oneOf([
        'payment',
        'balance-request',
        'instant-payment',
        'kok',
    ]).isRequired,
}

export default EditButton
