import { Outlet } from 'react-router-dom'

import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Sidebar from '../../components/Sidebar/Sidebar'

const Admin = () => {
    return (
        <>
            <div className="flex w-full flex-1 h-full">
                <div className="hidden lg:block md:min-w-[20%] lg:min-w-[16.6666%] bg-white">
                    <Sidebar forAdmin={true} />
                </div>

                <div className="w-full lg:w-5/6 bg-white">
                    <div className="flex flex-col">
                        <Header
                            includeLogo={true}
                            withBorder={true}
                            includeButtons={true}
                            includeProfile={false}
                        />

                        <main>
                            <Outlet />
                        </main>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Admin
