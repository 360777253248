import Modal from '../components/Modal/Modal'
import Header from '../components/Header/Header'
import HistoryTable from '../components/HistoryTable/HistoryTable'
import HistoryItem from '../components/mobile-components/HistoryItem/HistoryItem'
import useMediaQuery from '../hooks/useMediaQuery'
import { useEffect, useContext } from 'react'
import Loading from '../components/Loading/Loading'
import { getCurrentUserFines } from '../api/kok-fines'
import useAuth from '../hooks/useAuth'
import { useQuery } from '@tanstack/react-query'
import { formatAmount, formatDate } from '../helpers/formatters'
import StatusText from '../components/StatusText/StatusText'
import { AuthContext } from '../contexts/AuthContext'

const FinesHistory = () => {
    useEffect(() => {
        document.title =
            'Ιστορικό Κλήσεων Κ.Ο.Κ. - Πλατφόρμα Ηλ. Πληρωμών Δήμου Δυτικής Λέσβου'
    }, [])

    const isMobile = useMediaQuery('(max-width:1024px)')

    const { getCurrentUserID } = useAuth()
    const { token } = useContext(AuthContext)

    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }
    const userId = getCurrentUserID()

    const { isLoading, data } = useQuery({
        queryKey: ['userKokFines', userId, options],
        queryFn: () => getCurrentUserFines(userId, options),
    })

    if (isLoading) {
        return <Loading />
    }

    const headings = [
        'Ημερομηνία Κλήσης',
        'Αριθμός Κλήσης',
        'Αριθμός Κυκλοφορίας',
        'Όχημα',
        'Ποσό Κλήσης',
        'Κατάσταση',
    ]
    const rows = data?.kokFines
        .filter(fine => fine.status === 'COMPLETED')
        .map((fine, index) => ({
            0: formatDate(fine.createdAt) ?? '-',
            1: fine.fineNumber ?? '-',
            2: fine.vehicleNumber ?? '-',
            3: fine.vehicle ?? '-',
            4: formatAmount(fine.amount) ?? '-',
            5: <StatusText key={index} kind="payment" status={fine.status} />,
        }))
        .filter(row => Object.values(row).some(value => value !== '-'))

    return (
        <>
            {isMobile ? (
                <>
                    <h2 className="text-primary text-2xl text-center my-8">
                        Ιστορικό Κλήσεων Κ.Ο.Κ.
                    </h2>

                    {rows.length > 0 ? (
                        rows.map(fine => {
                            return (
                                <HistoryItem
                                    key={fine.id}
                                    headings={headings}
                                    rows={rows}
                                />
                            )
                        })
                    ) : (
                        <h2 className="text-base text-primary font-bold text-center">
                            Δεν βρέθηκαν κλήσεις Κ.Ο.Κ.
                        </h2>
                    )}
                </>
            ) : (
                <Modal classes="w-full max-w-7xl flex flex-col">
                    <Header
                        includeLogo={false}
                        withBorder={true}
                        includeButtons={true}
                    />

                    <div className="flex flex-col justify-start items-center grow">
                        <h2 className="text-primary text-3xl font-light mt-12 mb-6">
                            Ιστορικό Κλήσεων Κ.Ο.Κ.
                        </h2>

                        <HistoryTable
                            headings={headings}
                            rows={rows}
                            noDataText="Δεν βρέθηκαν κλήσεις Κ.Ο.Κ."
                        />
                    </div>
                </Modal>
            )}
        </>
    )
}

export default FinesHistory
