import Modal from '../../components/Modal/Modal'
import PopupMessage from '../../components/PopupMessage/PopupMessage'
import { useEffect, useState, useContext } from 'react'
import FormField from '../../components/FormField/FormField'
import { validateAfm } from '../../helpers/validators'
import { AuthContext } from '../../contexts/AuthContext'
import { Link } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { createKokFine } from '../../api/kok-fines'
import { useNavigate } from 'react-router-dom'

const KokCreation = () => {
    useEffect(() => {
        document.title =
            'Προσθήκη Κλήσης Κ.Ο.Κ. - Πλατφόρμα Ηλ. Πληρωμών Δήμου Δυτικής Λέσβου'
    }, [])

    const [popup, setPopup] = useState({ message: '', type: '', shown: false })
    const navigate = useNavigate()

    const { token } = useContext(AuthContext)

    const mutation = useMutation({
        mutationFn: kokFine =>
            createKokFine({
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(kokFine),
            }),
        onSuccess: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <p>
                        Η κλήση υποβλήθηκε με επιτυχία! Μπορείτε να δείτε τη
                        κλήση στον πίνακα με τις&nbsp;
                        <Link className="font-bold" to={'/admin/kok'}>
                            &quot;Κλησεις Κ.Ο.Κ.&quot;
                        </Link>
                    </p>
                ),
                type: 'success',
            }))

            setTimeout(() => {
                navigate('/admin/kok')
            }, 3000)
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Πρόεκυψε σφάλμα.</p>
                        <p>Παρακαλώ προσπαθήστε ξανά</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    const [formValues, setFormValues] = useState({
        afm: '',
        kokNumber: '',
        vehicleNumber: '',
        vehicle: '',
        dateIssued: '',
        amount: 0,
    })

    const handleChange = e => {
        const { name, value } = e.target
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }))
    }

    const handleSave = () => {
        const isEmpty = Object.values(formValues).some(
            value => value === '' || value === null
        )

        if (isEmpty) {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Προέκυψε σφάλμα! </p>
                        <p>Παρακαλώ συμπληρώστε όλα τα πεδία</p>
                    </>
                ),
                type: 'error',
            }))
        } else {
            if (!validateAfm(formValues.afm)) {
                setPopup(values => ({
                    ...values,
                    shown: true,
                    message: (
                        <>
                            <p>Προέκυψε σφάλμα! </p>
                            <p>Παρακαλώ εισάγετε έγκυρο ΑΦΜ</p>
                        </>
                    ),
                    type: 'error',
                }))
                return
            }

            const kokObject = {
                taxid: formValues.afm,
                fine: {
                    amount: parseInt(formValues.amount),
                    fineNumber: formValues.kokNumber,
                    vehicleNumber: formValues.vehicleNumber,
                    vehicle: formValues.vehicle,
                    dateIssued: `${formValues.dateIssued}:00.000Z`,
                },
            }
            mutation.mutate(kokObject)
        }
    }

    return (
        <>
            {popup.shown && (
                <PopupMessage
                    message={popup.message}
                    type={popup.type}
                    closePopUp={() =>
                        setPopup(values => ({ ...values, shown: false }))
                    }
                    showCloseButton={true}
                />
            )}

            <div className="flex flex-col">
                <div className="flex flex-col pb-32">
                    <header className="text-3xl text-center py-8">
                        Καταχώρηση Κλήσης Κ.Ο.Κ
                    </header>

                    <Modal classes="w-[80%] px-5 md:px-10 pb-8 pt-20 self-center md:w-1/2">
                        <div className="flex flex-col self-center justify-around px-10">
                            <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                <div className="w-full text-secondary">
                                    <FormField
                                        type="text"
                                        label="ΑΦΜ"
                                        name="afm"
                                        value={formValues.afm}
                                        onChange={handleChange}
                                        classes={
                                            '!justify-start gap-5 flex-wrap !text-left'
                                        }
                                    />
                                </div>
                            </div>
                            <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                <div className="w-full text-secondary">
                                    <FormField
                                        type="text"
                                        label="Αριθμός Κλήσης"
                                        name="kokNumber"
                                        value={formValues.kokNumber}
                                        onChange={handleChange}
                                        classes={
                                            '!justify-start gap-5 flex-wrap !text-left'
                                        }
                                    />
                                </div>
                            </div>
                            <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                <div className="w-full text-secondary">
                                    <FormField
                                        type="text"
                                        label="Αριθμός Κυκλοφορίας"
                                        name="vehicleNumber"
                                        value={formValues.vehicleNumber}
                                        onChange={handleChange}
                                        classes={
                                            '!justify-start gap-5 flex-wrap !text-left'
                                        }
                                    />
                                </div>
                            </div>
                            <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                <div className="w-full text-secondary">
                                    <FormField
                                        type="text"
                                        label="Όχημα"
                                        name="vehicle"
                                        value={formValues.vehicle}
                                        onChange={handleChange}
                                        classes={
                                            '!justify-start gap-5 flex-wrap !text-left'
                                        }
                                    />
                                </div>
                            </div>
                            <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                <div className="w-full text-secondary">
                                    <FormField
                                        type="datetime-local"
                                        label="Ημερομηνία έκδοσης"
                                        name="dateIssued"
                                        value={formValues.dateIssued}
                                        onChange={handleChange}
                                        classes={
                                            '!justify-start gap-5 flex-wrap !text-left'
                                        }
                                    />
                                </div>
                            </div>
                            <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                <div className="w-full text-secondary">
                                    <FormField
                                        type="number"
                                        label="Ποσό"
                                        name="amount"
                                        value={formValues.amount}
                                        onChange={handleChange}
                                        classes={
                                            '!justify-start gap-5 flex-wrap !text-left'
                                        }
                                    />
                                </div>
                            </div>
                            <button
                                className="mt-14 bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all rounded-lg w-fit py-3.5 px-10 self-center md:self-end"
                                type="submit"
                                onClick={handleSave}
                            >
                                Υποβολή
                            </button>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}

export default KokCreation
