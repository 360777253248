import PropTypes from 'prop-types'
import downloadPaymentIcon from '../../assets/images/download-payment.svg'
import { generatePDF } from '../../helpers/createPdf'

const DownloadButton = ({ payment, profile }) => {
    return (
        <button onClick={() => generatePDF(payment, profile)}>
            <img src={downloadPaymentIcon} alt="download" />
        </button>
    )
}

DownloadButton.propTypes = {
    payment: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
}

export default DownloadButton
