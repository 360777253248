import { useState } from 'react'
import PropTypes from 'prop-types'
import StatusDropDown from '../StatusDropDown/StatusDropDown'

import '../TableFilters/TableFilters.css'

const TableFilters = ({ kind, filterValues, handleFilterChange }) => {
    const [values, setValues] = useState({
        afm: '',
        title: '',
        status: '',
    })
    const handleChange = e => {
        const name = e.target?.name ?? 'status'
        const value = e.target?.value ?? e.value
        setValues(prevValues => ({ ...prevValues, [name]: value }))
        handleFilterChange({ ...values, [name]: value })
    }

    const clearFilters = () => {
        setValues({
            afm: '',
            title: '',
            status: '',
        })

        handleFilterChange({
            afm: '',
            title: '',
            status: '',
        })
    }

    return (
        <>
            <div className="lg:w-full max-w-7xl mt-12 filterBar p-5 rounded-xl">
                <form className="flex flex-col lg:flex-row gap-3 lg:justify-around">
                    <div className="flex gap-4 justify-between lg:items-center">
                        <label>ΑΦΜ Οφειλέτη:</label>
                        <input
                            className="w-2/3 lg:w-fit"
                            type="text"
                            name="afm"
                            onChange={handleChange}
                            value={filterValues.afm}
                        />
                    </div>
                    {kind === 'balance-request' ? (
                        <div className="flex gap-4 justify-between lg:items-center">
                            <label>Τίτλος:</label>
                            <input
                                className="w-2/3 lg:w-fit"
                                type="text"
                                name="title"
                                onChange={handleChange}
                                value={filterValues.title}
                            />
                        </div>
                    ) : null}

                    <div className="flex gap-4 justify-between lg:items-center">
                        <StatusDropDown
                            kind={kind}
                            status={filterValues.status}
                            onChange={e => {
                                handleChange(e)
                            }}
                        />
                    </div>
                </form>
            </div>

            <button className="mt-5 underline" onClick={clearFilters}>
                Καθαρισμός φίλτρων
            </button>
        </>
    )
}

TableFilters.propTypes = {
    kind: PropTypes.oneOf(['payment', 'balance-request', 'instant-payment'])
        .isRequired,
    handleFilterChange: PropTypes.func,
    filterValues: PropTypes.object,
}

export default TableFilters
