import _fetch from '.'

export async function getBalanceRequestsOfUser(userId, options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/users/${userId}/balance-requests`
    return _fetch(url, options)
}

export async function getBalanceRequest(balanceRequestId, options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/balance-requests/${balanceRequestId}`
    return _fetch(url, options)
}

export async function getAllBalanceRequests(filters, options = {}) {
    const { afm: taxid, status, title } = filters

    const url = new URL(`${process.env.REACT_APP_API_URL}/balance-requests`)

    url.searchParams.set('taxid', taxid)
    url.searchParams.set('status', status)
    url.searchParams.set('title', title)

    return _fetch(url, options)
}

export async function createBalanceRequest(options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/balance-requests`
    return _fetch(url, options)
}

export async function updateBalanceRequest(balanceRequestId, options = {}) {
    const url = `${process.env.REACT_APP_API_URL}/balance-requests/${balanceRequestId}`
    return _fetch(url, options)
}
