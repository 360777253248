import PropTypes from 'prop-types'

import SquareSelected from '../../assets/images/squareSelected.svg'
import SquareUnselected from '../../assets/images/squareUnselected.svg'

const BoxSelectButton = ({
    debtId,
    debtTotalAmount,
    debtsToPay,
    setDebtsToPay,
}) => {
    const selectDebt = (debtID, debtTotalAmount) => {
        if (!debtTotalAmount) {
            debtTotalAmount = 0.0
        }

        setDebtsToPay(values => ({
            ...values,
            totalAmount: debtsToPay.totalAmount + parseFloat(debtTotalAmount),
            debtsId: [...values.debtsId, debtID],
        }))
    }

    const unselectDebt = (debtID, debtTotalAmount) => {
        if (!debtTotalAmount) {
            debtTotalAmount = 0.0
        }

        setDebtsToPay(values => ({
            ...values,
            totalAmount: debtsToPay.totalAmount - parseFloat(debtTotalAmount),
            debtsId: debtsToPay.debtsId.filter(function (selectedIDs) {
                return selectedIDs !== debtID
            }),
        }))
    }

    return !debtsToPay.debtsId.includes(debtId) ? (
        <button
            className="w-5 "
            onClick={() => selectDebt(debtId, debtTotalAmount)}
        >
            <img
                className="mx-auto"
                src={SquareUnselected}
                alt="select-button"
            />
        </button>
    ) : (
        <button
            className="w-5"
            onClick={() => unselectDebt(debtId, debtTotalAmount)}
        >
            <img
                className="mx-auto"
                src={SquareSelected}
                alt="unselect-button"
            />
        </button>
    )
}

BoxSelectButton.propTypes = {
    debtId: PropTypes.number.isRequired,
    debtTotalAmount: PropTypes.number.isRequired,
    debtsToPay: PropTypes.object.isRequired,
    setDebtsToPay: PropTypes.func.isRequired,
}

export default BoxSelectButton
