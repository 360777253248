import './Modal.css'

import PropTypes from 'prop-types'

const Modal = props => {
    return (
        <div
            className={`modal-wrapper bg-white rounded-3xl  ${
                props.classes ?? ''
            }`}
        >
            {props.children}
        </div>
    )
}

Modal.propTypes = {
    classes: PropTypes.string,
    children: PropTypes.any,
}

export default Modal
